// src/components/Authentication/SignUpSteps/Step4Form.jsx

import React, { useState, useContext } from 'react';
import SelectField from '../../FormBuilder/Fields/SelectField';
import ColorPicker from '../../FormBuilder/Fields/ColorPicker';
import ImagePicker from '../../FormBuilder/Fields/ImagePicker';
import Spinner from '../../Common/Spinner';
import Tabs from '../../Common/Tabs';
import { useTranslation } from 'react-i18next';
import { ValidationContext } from '../../../contexts/ValidationContext';
import './Step4Form.css';

const Step4Form = ({ onNext, formData }) => {
  const { t } = useTranslation();
  const { checkSubdomainUnique } = useContext(ValidationContext);

  // Tabs
  const [selectedTab, setSelectedTab] = useState('general');

  // General Info
  const [profilePicture, setProfilePicture] = useState(formData.profilePicture || null);
  const [organizationName, setOrganizationName] = useState(formData.organizationName || '');
  const [subdomain, setSubdomain] = useState(formData.subdomain || '');
  const [timezone, setTimezone] = useState(formData.timezone || '');
  const [language, setLanguage] = useState(formData.language || '');
  const [color, setColor] = useState(formData.color || '#000000');

  // Address
  const [addressLine1, setAddressLine1] = useState(formData.addressLine1 || '');
  const [addressLine2, setAddressLine2] = useState(formData.addressLine2 || '');
  const [city, setCity] = useState(formData.city || '');
  const [state, setState] = useState(formData.state || '');
  const [zipCode, setZipCode] = useState(formData.zipCode || '');
  const [country, setCountry] = useState(formData.country || '');

  const [loading, setLoading] = useState(false);
  const [subdomainError, setSubdomainError] = useState('');

  // New state to track validation errors
  const [errors, setErrors] = useState({});

  // Consolidate data to pass along
  const currentData = {
    profilePicture,
    organizationName,
    subdomain,
    timezone,
    language,
    color,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    country,
  };

  // Validate required fields from both tabs
  const validate = () => {
    const newErrors = {};
    if (!profilePicture) newErrors.profilePicture = t('This field is required');
    if (!organizationName.trim()) newErrors.organizationName = t('This field is required');
    if (!subdomain.trim()) newErrors.subdomain = t('This field is required');
    if (!timezone) newErrors.timezone = t('This field is required');
    if (!language) newErrors.language = t('This field is required');
    if (!addressLine1.trim()) newErrors.addressLine1 = t('This field is required');
    if (!city.trim()) newErrors.city = t('This field is required');
    if (!state) newErrors.state = t('This field is required');
    if (!zipCode.trim()) newErrors.zipCode = t('This field is required');
    if (!country) newErrors.country = t('This field is required');
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSubdomainError('');

    // Run local validations first
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false);
      return; // Prevent moving to the next step if required fields are missing
    }

    try {
      // Validate that the subdomain is unique
      const isUnique = await checkSubdomainUnique(subdomain);
      if (!isUnique) {
        setSubdomainError(t('Subdomain is already taken'));
        setLoading(false);
        return;
      }
      onNext(currentData);
    } catch (error) {
      console.error('Error proceeding to summary:', error);
    } finally {
      setLoading(false);
    }
  };

  // Define which fields belong to each tab
  const generalFields = ['profilePicture', 'organizationName', 'subdomain', 'timezone', 'language'];
  const addressFields = ['addressLine1', 'city', 'state', 'zipCode', 'country'];

  // Check whether there are errors on each tab (based on our errors state)
  const generalHasError = generalFields.some((field) => errors[field]);
  const addressHasError = addressFields.some((field) => errors[field]);

  // Build the tabs array with a "blink" property if errors exist on that tab and it is not active
  const tabs = [
    {
      id: 'general',
      label: t('signUp.generalInformation'),
      blink: generalHasError && selectedTab !== 'general',
    },
    {
      id: 'address',
      label: t('signUp.address'),
      blink: addressHasError && selectedTab !== 'address',
    },
  ];

  return (
    <div className="step4-container">
      <h2>{t('signUp.step4Title')}</h2>
      <form onSubmit={handleSubmit} className="sign-up-form">
        <Tabs tabs={tabs} selectedTab={selectedTab} onTabSelect={setSelectedTab} />

        <div className="tab-content">
          {selectedTab === 'general' && (
            <div className="tab-panel">
              {/* Organization Logo (Profile Picture) */}
              <div className="sign-up-input-group full-width">
                <ImagePicker
                  name="profilePicture"
                  value={profilePicture}
                  onChange={(value) => {
                    setProfilePicture(value);
                    if (errors.profilePicture) {
                      setErrors((prev) => ({ ...prev, profilePicture: undefined }));
                    }
                  }}
                  required
                  imageShape="circular"
                  maxFileSize={5120}
                  maxFinalSizeKB={500}
                  maxDimension={200}
                  shouldCompress={false}
                />
                {errors.profilePicture && <span className="error-message">{errors.profilePicture}</span>}
              </div>

              {/* Group Organization Name and Subdomain in two columns */}
              <div className="sign-up-input-row">
                <div className="sign-up-input-group half-width">
                  <label htmlFor="organizationName">
                    {t('signUp.organizationNameLabel')}
                    <span className="required-asterisk">*</span>
                  </label>
                  <input
                    id="organizationName"
                    type="text"
                    value={organizationName}
                    onChange={(e) => {
                      setOrganizationName(e.target.value);
                      if (errors.organizationName) {
                        setErrors((prev) => ({ ...prev, organizationName: undefined }));
                      }
                    }}
                    placeholder={t('signUp.organizationNamePlaceholder')}
                    required
                    className={errors.organizationName ? 'error' : ''}
                  />
                  {errors.organizationName && <span className="error-message">{errors.organizationName}</span>}
                </div>
                <div className="sign-up-input-group half-width">
                  <label htmlFor="subdomain">
                    {t('signUp.subdomainLabel')}
                    <span className="required-asterisk">*</span>
                  </label>
                  <input
                    id="subdomain"
                    type="text"
                    value={subdomain}
                    onChange={(e) => {
                      setSubdomain(e.target.value);
                      if (errors.subdomain) {
                        setErrors((prev) => ({ ...prev, subdomain: undefined }));
                      }
                    }}
                    placeholder={t('signUp.subdomainPlaceholder')}
                    required
                    className={errors.subdomain ? 'error' : ''}
                  />
                  {errors.subdomain && <span className="error-message">{errors.subdomain}</span>}
                  {subdomainError && <span className="error-message">{subdomainError}</span>}
                </div>
              </div>

              {/* Group Timezone and Language in two columns */}
              <div className="sign-up-input-row">
                <div className="sign-up-input-group half-width">
                  <SelectField
                    label={t('signUp.timezoneLabel')}
                    name="timezone"
                    value={timezone}
                    onChange={(value) => {
                      setTimezone(value);
                      if (errors.timezone) {
                        setErrors((prev) => ({ ...prev, timezone: undefined }));
                      }
                    }}
                    placeholder={t('signUp.selectTimezonePlaceholder')}
                    catalogId="timezones"
                    required
                    className={errors.timezone ? 'error' : ''}
                  />
                  {errors.timezone && <span className="error-message">{errors.timezone}</span>}
                </div>
                <div className="sign-up-input-group half-width">
                  <SelectField
                    label={t('signUp.languageLabel')}
                    name="language"
                    value={language}
                    onChange={(value) => {
                      setLanguage(value);
                      if (errors.language) {
                        setErrors((prev) => ({ ...prev, language: undefined }));
                      }
                    }}
                    placeholder={t('signUp.selectLanguagePlaceholder')}
                    catalogId="languages"
                    required
                    className={errors.language ? 'error' : ''}
                  />
                  {errors.language && <span className="error-message">{errors.language}</span>}
                </div>
              </div>

              {/* Color Picker remains full width */}
              <div className="sign-up-input-group full-width">
                <ColorPicker
                  label={t('signUp.colorLabel')}
                  value={color}
                  onChange={setColor}
                  required
                />
              </div>
            </div>
          )}

          {selectedTab === 'address' && (
            <div className="tab-panel">
              {/* Address Lines */}
              <div className="sign-up-input-group full-width">
                <label htmlFor="addressLine1">
                  {t('signUp.addressLine1Label')}
                  <span className="required-asterisk">*</span>
                </label>
                <input
                  id="addressLine1"
                  type="text"
                  value={addressLine1}
                  onChange={(e) => {
                    setAddressLine1(e.target.value);
                    if (errors.addressLine1) {
                      setErrors((prev) => ({ ...prev, addressLine1: undefined }));
                    }
                  }}
                  placeholder={t('signUp.addressLine1Placeholder')}
                  required
                  className={errors.addressLine1 ? 'error' : ''}
                />
                {errors.addressLine1 && <span className="error-message">{errors.addressLine1}</span>}
              </div>
              <div className="sign-up-input-group full-width">
                <label htmlFor="addressLine2">{t('signUp.addressLine2Label')}</label>
                <input
                  id="addressLine2"
                  type="text"
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                  placeholder={t('signUp.addressLine2Placeholder')}
                />
              </div>

              {/* Group City and State in two columns */}
              <div className="sign-up-input-row">
                <div className="sign-up-input-group half-width">
                  <label htmlFor="city">
                    {t('signUp.cityLabel')}
                    <span className="required-asterisk">*</span>
                  </label>
                  <input
                    id="city"
                    type="text"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                      if (errors.city) {
                        setErrors((prev) => ({ ...prev, city: undefined }));
                      }
                    }}
                    placeholder={t('signUp.cityPlaceholder')}
                    required
                    className={errors.city ? 'error' : ''}
                  />
                  {errors.city && <span className="error-message">{errors.city}</span>}
                </div>
                <div className="sign-up-input-group half-width">
                  <SelectField
                    label={t('signUp.stateLabel')}
                    name="state"
                    value={state}
                    onChange={(value) => {
                      setState(value);
                      if (errors.state) {
                        setErrors((prev) => ({ ...prev, state: undefined }));
                      }
                    }}
                    placeholder={t('signUp.selectStatePlaceholder')}
                    catalogId="states"
                    required
                    className={errors.state ? 'error' : ''}
                  />
                  {errors.state && <span className="error-message">{errors.state}</span>}
                </div>
              </div>

              {/* Group Zip Code and Country in two columns */}
              <div className="sign-up-input-row">
                <div className="sign-up-input-group half-width">
                  <label htmlFor="zipCode">
                    {t('signUp.zipCodeLabel')}
                    <span className="required-asterisk">*</span>
                  </label>
                  <input
                    id="zipCode"
                    type="text"
                    value={zipCode}
                    onChange={(e) => {
                      // Remove non-digit characters and truncate to 5 digits
                      const digitsOnly = e.target.value.replace(/\D/g, '');
                      const truncated = digitsOnly.slice(0, 5);
                      setZipCode(truncated);
                      if (errors.zipCode) {
                        setErrors((prev) => ({ ...prev, zipCode: undefined }));
                      }
                    }}
                    placeholder={t('signUp.zipCodePlaceholder')}
                    required
                    className={errors.zipCode ? 'error' : ''}
                    maxLength="5"
                    inputMode="numeric"
                    pattern="\d*"
                  />
                  {errors.zipCode && <span className="error-message">{errors.zipCode}</span>}
                </div>
                <div className="sign-up-input-group half-width">
                  <SelectField
                    label={t('signUp.countryLabel')}
                    name="country"
                    value={country}
                    onChange={(value) => {
                      setCountry(value);
                      if (errors.country) {
                        setErrors((prev) => ({ ...prev, country: undefined }));
                      }
                    }}
                    placeholder={t('signUp.selectCountryPlaceholder')}
                    catalogId="countries"
                    required
                    className={errors.country ? 'error' : ''}
                  />
                  {errors.country && <span className="error-message">{errors.country}</span>}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="form-navigation-buttons">
          <button type="submit" className="sign-up-submit-button" disabled={loading}>
            {loading ? <Spinner /> : t('signUp.nextButton')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step4Form;
