// src/firebase.js

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

// v8 app-check compatibility
import 'firebase/compat/app-check';


// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '',
};

let firebaseApp;

try {
  // Check if all required config values are present
  const isFirebaseConfigValid = Object.values(firebaseConfig).every((value) => value !== '');

  if (!isFirebaseConfigValid) {
    throw new Error('Firebase configuration is invalid. Please check your environment variables.');
  }

  // Initialize Firebase only if it hasn't been initialized yet
  if (!firebase.apps.length) {
    firebaseApp = firebase.initializeApp(firebaseConfig);
    console.log('Firebase initialized successfully.');

    // ===== [APP CHECK ACTIVATION] =====
    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    if (recaptchaSiteKey) {
      // Enable debug mode for local testing (remove for production)
      if (window.location.hostname === "localhost") {
        window.FIREBASE_APPCHECK_DEBUG_TOKEN =  process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
      }

      const appCheck = firebase.appCheck();

      // Activate App Check with your reCAPTCHA v3 site key
      // The second param (true) enables automatic token refresh
      appCheck.activate(recaptchaSiteKey, true);
      console.log('Firebase App Check activated with reCAPTCHA v3.');

      // (Optional) Retrieve the current App Check token for debugging
      appCheck
        .getToken(/* forceRefresh= */ true)
        .then((result) => {
          console.log('App Check token (debug):', result.token);
        })
        .catch((err) => {
          console.error('Error retrieving App Check token:', err);
        });
    } else {
      console.warn('REACT_APP_RECAPTCHA_SITE_KEY not found. App Check not activated.');
    }
  } else {
    firebaseApp = firebase.app(); // Use the existing initialized app
    console.log('Firebase app already initialized.');
  }
} catch (error) {
  console.error('Firebase initialization error:', error);
  alert('Error initializing Firebase. Please check your configuration.');
}

// Export Firestore, Auth, and Storage if initialization was successful
export const firestore = firebaseApp ? firebaseApp.firestore() : null;
export const auth = firebaseApp ? firebaseApp.auth() : null;
export const storage = firebaseApp ? firebaseApp.storage() : null;

// Enable Firestore Offline Persistence
if (firestore) {
  firestore
    .enablePersistence()
    .then(() => {
      console.log('Firestore offline persistence enabled.');
    })
    .catch((err) => {
      if (err.code === 'failed-precondition') {
        console.warn('Firestore persistence failed: Multiple tabs open.');
      } else if (err.code === 'unimplemented') {
        console.warn('Firestore persistence is not available in this browser.');
      } else {
        console.error('Error enabling Firestore persistence:', err);
      }
    });
}
