// src/contexts/EventContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { firestore } from '../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  getDoc,
  orderBy,
  limit,
} from 'firebase/firestore';
import { AppSettingsContext } from './AppSettingsContext';
import { UserContext } from './UserContext';

// Import Firebase and App Check modules
import firebase from 'firebase/compat/app';
import 'firebase/compat/app-check';

export const EventContext = createContext();

export const EventProvider = ({ children }) => {
  const { currentUser } = useContext(UserContext);
  const { organizationData } = useContext(AppSettingsContext);
  const [events, setEvents] = useState([]);
  const [isEventsLoading, setIsEventsLoading] = useState(false);
  const [isEventsError, setIsEventsError] = useState(null);

  // Helper: remove undefined fields from an object
  const removeUndefined = (obj) =>
    Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined));

  /**
   * Returns the start/end date for ±1 year coverage,
   * anchored at the first day of the current month.
   */
  const getCoverageWindow = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    // Start = 1 year before
    const startOfWindow = new Date(startOfMonth);
    startOfWindow.setFullYear(startOfWindow.getFullYear() - 1);

    // End = 1 year after
    const endOfWindow = new Date(startOfMonth);
    endOfWindow.setFullYear(endOfWindow.getFullYear() + 1);

    return { startOfWindow, endOfWindow };
  };

  /**
   * Fetch normal (non-liturgy) events for the user's organization.
   */
  const fetchOrgEventsFromFirestore = async () => {
    if (!currentUser) {
      console.error('User is not authenticated.');
      return [];
    }

    try {
      const eventsCollectionRef = collection(firestore, 'events');
      // Query events that match the user's organizationId, status in ['published','draft']
      const orgEventsQuery = query(
        eventsCollectionRef,
        where('organizationId', '==', organizationData.organizationId),
        where('status', 'in', ['published', 'draft'])
      );

      const snapshot = await getDocs(orgEventsQuery);
      const orgEvents = snapshot.docs
        .map((docSnap) => {
          const data = docSnap.data();
          // Skip draft events not created by the current user
          if (data.status === 'draft' && data.userId !== currentUser.uid) return null;

          // If visibility=closed, must match groupId
          // If visibility=open, ok
          // else skip
          if (data.visibility === 'closed' && data.groupId !== organizationData.groupId) {
            return null;
          } else if (data.visibility === 'open') {
            // org-wide
          } else if (!data.visibility) {
            // invalid or missing => skip
            return null;
          }

          return {
            id: docSnap.id,
            ...data,
            startDateTime: data.startDateTime?.toDate() || null,
            endDateTime: data.endDateTime?.toDate() || null,
            recurringDays: data.recurringDays ? data.recurringDays.map(String) : [],
            exceptionDates: data.exceptionDates || [],
            // if data.type missing, default to 'event'
            type: data.type || 'event',
          };
        })
        .filter(Boolean);

      return orgEvents;
    } catch (error) {
      console.error('Error fetching org events:', error);
      setIsEventsError(error);
      return [];
    }
  };

  /**
   * Fetch global liturgy events from Firestore (organizationId='global').
   */
  const fetchGlobalLiturgyEventsFromFirestore = async () => {
    try {
      const eventsCollectionRef = collection(firestore, 'events');
      const litQuery = query(
        eventsCollectionRef,
        where('organizationId', '==', 'global'),
        where('type', '==', 'liturgy'),
        where('visibility', '==', 'open'),
        where('status', 'in', ['published', 'draft'])
      );
      const snapshot = await getDocs(litQuery);

      const globalLitEvents = snapshot.docs.map((docSnap) => {
        const data = docSnap.data();
        return {
          id: docSnap.id,
          ...data,
          startDateTime: data.startDateTime?.toDate() || null,
          endDateTime: data.endDateTime?.toDate() || null,
          recurringDays: data.recurringDays ? data.recurringDays.map(String) : [],
          exceptionDates: data.exceptionDates || [],
          type: 'liturgy',
        };
      });
      return globalLitEvents;
    } catch (error) {
      console.error('Error fetching global liturgy events:', error);
      setIsEventsError(error);
      return [];
    }
  };

  /**
   * Possibly fetch more liturgical events if we do not have full coverage.
   * This function now includes an App Check token in its API call.
   */
  const fetchLiturgicalEventsFromAPI = async (startDate, endDate) => {
    const requestData = {
      startYear: startDate.getFullYear(),
      startMonth: startDate.getMonth() + 1,
      endYear: endDate.getFullYear(),
      endMonth: endDate.getMonth() + 1,
      lang: 'en',
    };

    // Retrieve the App Check token (force refresh by passing true)
    const tokenResult = await firebase.appCheck().getToken(true);
    const appCheckToken = tokenResult.token;

    const response = await fetch('https://getliturgicalevents-lgfph5hmwq-uc.a.run.app', {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'X-Firebase-AppCheck': appCheckToken,
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error('Error fetching liturgical events');
    }

    const result = await response.json();
    // Filter out generic "Week", "after", "Octave", "Weekday", etc.
    const filtered = result.data.filter((ev) => {
      const text = ev.text || '';
      if (
        /\bweek\b/i.test(text) ||
        /\bafter\b/i.test(text) ||
        /\boctave\b/i.test(text) ||
        /\bweekday\b/i.test(text)
      ) {
        return false;
      }
      return true;
    });

    return filtered.map((ev) => {
      const [year, month, day] = ev.date.split('-').map(Number);
      const eventDate = new Date(year, month - 1, day);
      return {
        name: ev.text,
        isAllDay: true,
        startDateTime: eventDate,
        endDateTime: eventDate,
        type: 'liturgy',
        color: ev.color || '#663399',
        description: ev.text || '',
        visibility: 'open',
        status: 'published',
      };
    });
  };

  /**
   * Insert missing liturgical events into Firestore if coverage is incomplete.
   */
  const importLiturgicalEventsIfNeeded = async () => {
    if (!currentUser) return; // must be logged in

    const { startOfWindow, endOfWindow } = getCoverageWindow();
    const eventsCollectionRef = collection(firestore, 'events');

    // Query the latest global liturgy event
    const latestLitQuery = query(
      eventsCollectionRef,
      where('organizationId', '==', 'global'),
      where('type', '==', 'liturgy'),
      where('visibility', '==', 'open'),
      orderBy('endDateTime', 'desc'),
      limit(1)
    );

    const litSnapshot = await getDocs(latestLitQuery);

    let fetchStart = startOfWindow;
    if (!litSnapshot.empty) {
      const lastLitData = litSnapshot.docs[0].data();
      const lastEnd = lastLitData.endDateTime?.toDate() || startOfWindow;
      if (lastEnd >= endOfWindow) {
        // coverage is fine
        return;
      } else {
        const nextDay = new Date(lastEnd);
        nextDay.setDate(nextDay.getDate() + 1);
        if (nextDay > fetchStart) {
          fetchStart = nextDay;
        }
      }
    }

    if (fetchStart >= endOfWindow) return;

    try {
      const missingLitEvents = await fetchLiturgicalEventsFromAPI(fetchStart, endOfWindow);

      for (const litEvent of missingLitEvents) {
        const eventDate = litEvent.startDateTime;
        const dateString = eventDate.toISOString().split('T')[0]; // 'YYYY-MM-DD'
        const sanitizedText = (litEvent.name || '').replace(/\s+/g, '-').toLowerCase();
        const docId = `liturgy-${dateString}-${sanitizedText}`;

        const docRef = doc(eventsCollectionRef, docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // skip
          continue;
        }

        const cleaned = removeUndefined({
          ...litEvent,
          organizationId: 'global',
          userId: currentUser.uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        await setDoc(docRef, cleaned);
      }
    } catch (error) {
      console.error('Error importing liturgical events:', error);
      setIsEventsError(error);
    }
  };

  /**
   * Fetch & merge all relevant events (org + global liturgy).
   */
  const refreshEvents = async () => {
    setIsEventsLoading(true);
    try {
      // Only do coverage check occasionally (e.g. page load)
      await importLiturgicalEventsIfNeeded();

      const [orgEvents, globalLiturgyEvents] = await Promise.all([
        fetchOrgEventsFromFirestore(),
        fetchGlobalLiturgyEventsFromFirestore(),
      ]);

      setEvents([...orgEvents, ...globalLiturgyEvents]);
    } catch (error) {
      console.error('Error refreshing events:', error);
      setIsEventsError(error);
    } finally {
      setIsEventsLoading(false);
    }
  };

  // -------------------------------------------------------------------
  //   CRUD Methods
  // -------------------------------------------------------------------

  const addEvent = async (eventData) => {
    if (!eventData || !eventData.name) {
      throw new Error('Event data is required');
    }
    if (!currentUser) {
      throw new Error('User is not authenticated');
    }
    if (!organizationData?.organizationId || !organizationData?.groupId) {
      throw new Error('Organization data is not available.');
    }
    try {
      // Ensure newly created events have type: 'event' if missing
      if (!eventData.type) {
        eventData.type = 'event';
      }

      const eventsCollectionRef = collection(firestore, 'events');
      const cleanedEventData = removeUndefined(eventData);

      const newEvent = {
        ...cleanedEventData,
        organizationId: organizationData.organizationId,
        groupId: organizationData.groupId,
        userId: currentUser.uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        status: cleanedEventData.status || 'published',
        visibility: cleanedEventData.visibility || 'closed',
      };

      const docRef = await addDoc(eventsCollectionRef, newEvent);

      // Locally add the newly created event with docRef.id
      setEvents((prev) => [...prev, { id: docRef.id, ...newEvent }]);
    } catch (error) {
      console.error('Error adding event:', error);
      throw error;
    }
  };

  const editEvent = async (eventId, eventData) => {
    if (!eventId || !eventData) {
      throw new Error('Event ID and data are required');
    }
    if (!currentUser) {
      throw new Error('User is not authenticated');
    }
    try {
      const eventDocRef = doc(firestore, 'events', eventId);
      const cleanedEventData = removeUndefined(eventData);

      await updateDoc(eventDocRef, {
        ...cleanedEventData,
        userId: currentUser.uid,
        updatedAt: serverTimestamp(),
      });

      // Update local state
      setEvents((prevEvents) =>
        prevEvents.map((ev) =>
          ev.id === eventId ? { ...ev, ...cleanedEventData } : ev
        )
      );
    } catch (error) {
      console.error('Error editing event:', error);
      throw error;
    }
  };

  const deleteEventOccurrence = async (eventId, occurrenceDate) => {
    if (!eventId || !occurrenceDate) {
      throw new Error('Event ID and occurrence date are required');
    }
    try {
      const eventDocRef = doc(firestore, 'events', eventId);
      const eventSnapshot = await getDoc(eventDocRef);
      if (!eventSnapshot.exists()) {
        throw new Error('Event not found');
      }
      const eventData = eventSnapshot.data();
      const exceptionDates = eventData.exceptionDates || [];

      exceptionDates.push(occurrenceDate);

      await updateDoc(eventDocRef, {
        exceptionDates,
        updatedAt: serverTimestamp(),
      });

      // Locally update
      setEvents((prev) =>
        prev.map((ev) => {
          if (ev.id === eventId) {
            return {
              ...ev,
              exceptionDates: [...(ev.exceptionDates || []), occurrenceDate],
            };
          }
          return ev;
        })
      );
    } catch (error) {
      console.error('Error deleting event occurrence:', error);
      throw error;
    }
  };

  const deleteEvent = async (eventId) => {
    if (!eventId) {
      throw new Error('Event ID is required');
    }
    try {
      const eventDocRef = doc(firestore, 'events', eventId);
      await deleteDoc(eventDocRef);

      // Locally remove
      setEvents((prev) => prev.filter((ev) => ev.id !== eventId));
    } catch (error) {
      console.error('Error deleting event:', error);
      throw error;
    }
  };

  // On load
  useEffect(() => {
    if (organizationData && organizationData.groupId && currentUser) {
      refreshEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationData, currentUser]);

  return (
    <EventContext.Provider
      value={{
        events,
        isEventsLoading,
        isEventsError,
        fetchEvents: refreshEvents,
        addEvent,
        editEvent,
        deleteEvent,
        deleteEventOccurrence,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export default EventProvider;
