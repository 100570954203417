import React from 'react';
import PropTypes from 'prop-types';
import ActionButton from '../Common/ActionButton';
import './RolesForm.css';
import { useTranslation } from 'react-i18next';

import NumberField from '../FormBuilder/Fields/NumberField';
import TextField from '../FormBuilder/Fields/TextField';
import Tabs from '../Common/Tabs';
import IconMapSelector from '../Common/IconMapSelector';
import IconMap from '../Common/IconMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RolesForm = ({
  name,
  setName,
  description,
  setDescription,
  icon,
  setIcon,
  permissions,
  setPermissions,

  // Priority logic
  priority,
  setPriority,
  currentUserPriority, // so they can't set a smaller (higher rank) number
  // NEW: If org-level user for a child group => skip priority clamp
  canManageAsOrgLevel = false,

  isMobile,
  onSubmit,
  isEditing,
  isSubmitting,
  permissionOptions,
  organizationId,
  groupId,
  viewOnly,
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState('general');

  const tabs = [
    { id: 'general', label: t('rolesForm.generalTab', 'General') },
    { id: 'permissions', label: t('rolesForm.permissionsTab', 'Permissions') },
  ];

  // prevent submission if just "viewing"
  const handleFormSubmit = (e) => {
    if (viewOnly) {
      e.preventDefault();
      return;
    }
    onSubmit(e);
  };

  // Toggle entire module => all subPermissions
  const handleModuleToggle = (moduleValue) => {
    if (viewOnly) return;
    const updated = permissions.map((module) => {
      if (module.name === moduleValue) {
        const allChecked = module.subPermissions.every((sp) => sp.completed);
        return {
          ...module,
          subPermissions: module.subPermissions.map((sp) => ({
            ...sp,
            completed: !allChecked,
          })),
        };
      }
      return module;
    });
    setPermissions(updated);
  };

  // Toggle single sub-permission
  const handleSubPermissionToggle = (moduleValue, subValue) => {
    if (viewOnly) return;
    const updated = permissions.map((module) => {
      if (module.name === moduleValue) {
        return {
          ...module,
          subPermissions: module.subPermissions.map((sp) => {
            if (sp.name === subValue) {
              return { ...sp, completed: !sp.completed };
            }
            return sp;
          }),
        };
      }
      return module;
    });
    setPermissions(updated);
  };

  // Check if a module is fully selected
  const isModuleFullySelected = (moduleObj) => {
    if (!moduleObj || !moduleObj.subPermissions) return false;
    return moduleObj.subPermissions.every((sp) => sp.completed);
  };

  /**
   * If canManageAsOrgLevel => skip clamp
   * else clamp to currentUserPriority
   */
  const handlePriorityChange = (newVal) => {
    const parsed = Number(newVal);
    if (isNaN(parsed)) return;

    if (!canManageAsOrgLevel) {
      // clamp if user tries to set a smaller number
      if (parsed < currentUserPriority) {
        setPriority(currentUserPriority);
      } else {
        setPriority(parsed);
      }
    } else {
      // no clamp => set directly
      setPriority(parsed);
    }
  };

  return (
    <form className="roles-modal-form" onSubmit={handleFormSubmit}>
      <Tabs tabs={tabs} selectedTab={selectedTab} onTabSelect={setSelectedTab} />

      {/* ------ GENERAL TAB ------ */}
      {selectedTab === 'general' && (
        <div className="roles-tab-content general-tab-content">
          <div className="icon-selector-group">
            <IconMapSelector
              selectedIcon={icon}
              onChange={(val) => !viewOnly && setIcon(val)}
              placeholder={t('rolesForm.iconPlaceholder', 'Select Icon')}
              showPreview
              disabled={viewOnly}
            />
          </div>

          {/* Role Name */}
          <div className="roles-input-group left-aligned">
            <TextField
              label={t('rolesForm.nameLabel', 'Role Name')}
              name="roleName"
              value={name}
              onChange={(val) => !viewOnly && setName(val)}
              required={!viewOnly}
              placeholder={t('rolesForm.namePlaceholder', 'Enter role name')}
              size="full"
              disabled={viewOnly}
            />
          </div>

          {/* Description */}
          <div className="roles-input-group left-aligned">
            <TextField
              label={t('rolesForm.descriptionLabel', 'Description')}
              name="roleDescription"
              value={description}
              onChange={(val) => !viewOnly && setDescription(val)}
              placeholder={t('rolesForm.descriptionPlaceholder', 'Enter role description')}
              size="full"
              disabled={viewOnly}
            />
          </div>

          {/* Priority => skip clamp if org-level */}
          <div className="roles-input-group left-aligned">
            <NumberField
              label={t('rolesForm.priorityLabel', 'Priority (lower = higher rank)')}
              name="rolePriority"
              value={priority}
              onChange={handlePriorityChange}
              placeholder={t('rolesForm.priorityPlaceholder', 'Enter numeric priority')}
              min={canManageAsOrgLevel ? 1 : currentUserPriority}
              disabled={viewOnly}
            />
          </div>
        </div>
      )}

      {/* ------ PERMISSIONS TAB ------ */}
      {selectedTab === 'permissions' && (
        <div className="roles-tab-content">
          <label className="roles-label permissions-label">
            {t('rolesForm.permissionsLabel', 'Select Permissions')}
          </label>

          <div className="permissions-grid">
            {permissionOptions
              .filter((option) => {
                // If group-level => hide 'groups' or 'billing' if not used
                if (
                  (option.value === 'groups' || option.value === 'billing') &&
                  organizationId !== groupId
                ) {
                  return false;
                }
                return true;
              })
              .map((option) => {
                const moduleObj = permissions.find((p) => p.name === option.value);
                const fullySelected = isModuleFullySelected(moduleObj);

                return (
                  <div key={option.value} className="permission-module">
                    <div
                      className={`permission-card module-card ${
                        fullySelected ? 'selected' : ''
                      }`}
                      onClick={() => !viewOnly && handleModuleToggle(option.value)}
                    >
                      <div className="permission-card-header">
                        <FontAwesomeIcon
                          icon={
                            fullySelected
                              ? IconMap['faCheckSquare']
                              : IconMap['faSquare']
                          }
                          className="permission-card-checkbox"
                        />
                      </div>
                      <div className="permission-card-icon">
                        <FontAwesomeIcon icon={IconMap[option.icon]} />
                      </div>
                      <div className="permission-card-label">
                        {t(`rolesForm.${option.value}Label`, option.label)}
                      </div>

                      <div className="sub-permissions">
                        {option.subPermissions.map((sub) => {
                          const subObj = moduleObj?.subPermissions.find(
                            (sp) => sp.name === sub.value
                          );
                          const isChecked = subObj?.completed || false;

                          return (
                            <div
                              key={sub.value}
                              className={`sub-permission-item ${
                                isChecked ? 'selected' : ''
                              }`}
                              onClick={(e) => {
                                e.stopPropagation();
                                !viewOnly &&
                                  handleSubPermissionToggle(option.value, sub.value);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={
                                  isChecked
                                    ? IconMap['faCheckSquare']
                                    : IconMap['faSquare']
                                }
                                className="sub-permission-checkbox"
                              />
                              <span className="sub-permission-label">
                                {t(
                                  `rolesForm.${option.value}.${sub.value}Label`,
                                  sub.label
                                )}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}

      {/* Hide submit if viewOnly */}
      {!viewOnly && (
        <div className="roles-submit-button-container">
          <ActionButton
            type="submit"
            label={
              isSubmitting
                ? t('rolesForm.submitting', 'Submitting...')
                : isEditing
                ? t('rolesForm.updateButton', 'Update Role')
                : t('rolesForm.saveButton', 'Save Role')
            }
            text={
              isSubmitting
                ? t('rolesForm.submitting', 'Submitting...')
                : isEditing
                ? t('rolesForm.updateButton', 'Update Role')
                : t('rolesForm.saveButton', 'Save Role')
            }
            icon={isSubmitting ? 'faSpinner' : 'faSave'}
            spin={isSubmitting}
            disabled={isSubmitting}
            isMobile={isMobile}
            colorType="primary"
          />
        </div>
      )}
    </form>
  );
};

RolesForm.propTypes = {
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  setIcon: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      subPermissions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          completed: PropTypes.bool.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  setPermissions: PropTypes.func.isRequired,

  // Priority logic
  priority: PropTypes.number.isRequired,
  setPriority: PropTypes.func.isRequired,
  currentUserPriority: PropTypes.number.isRequired,

  // NEW: skip priority clamp if user is org-level
  canManageAsOrgLevel: PropTypes.bool,

  isMobile: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  viewOnly: PropTypes.bool.isRequired,

  permissionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      icon: PropTypes.string,
      subPermissions: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,

  organizationId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
};

export default RolesForm;
