// src/components/Policies/TermsService.jsx

import React from 'react';
import './TermsService.css';
import { Link } from 'react-router-dom';

const TermsService = () => {
  return (
    <div className="terms-service-background">
      <div className="terms-service-container">
        <header className="terms-service-header">
          <h1>Terms of Service</h1>
          <h2>Catholicore</h2>
        </header>

        <div className="terms-service-content">
          <div className="service-dates">
            <p><strong>Effective Date:</strong> December 26th, 2024</p>
            <p><strong>Last Updated:</strong> February 6th, 2025</p>
          </div>

          <article>
            <h3>Introduction</h3>
            <p>
              Thank you for choosing Catholicore, a dedicated platform for managing Catholic parishes and
              religious groups. These Terms of Service (“Terms”) govern your access to and use of the
              Catholicore website and services (collectively, “Services”). By accessing or using our Services,
              you agree to be bound by these Terms and our <Link to="/privacy-policy">Privacy Policy</Link>.
            </p>
          </article>

          <article>
            <h3>Using Our Services</h3>
            <ul>
              <li>
                <strong>Eligibility:</strong> You must be at least 18 years old to use our Services. By
                agreeing to these Terms, you represent that you are of legal age to form a binding contract.
              </li>
              <li>
                <strong>Account Registration:</strong> You agree to provide accurate and complete information
                during registration and to keep it updated.
              </li>
              <li>
                <strong>User Responsibilities:</strong> You are responsible for safeguarding your account credentials.
              </li>
              <li>
                <strong>Prohibited Activities:</strong> You agree not to use the Services for any illegal or unauthorized purpose.
              </li>
            </ul>
          </article>

          <article>
            <h3>Communications Consent</h3>
            <p>
              If you opt in to receive communications via our members form, you agree to receive emails,
              SMS messages, and push notifications from Catholicore. These communications may include service
              updates, promotional offers, and other notifications. You may change your communication preferences at any time.
            </p>
          </article>

          <article>
            <h3>Subscription and Billing</h3>
            <ul>
              <li>
                <strong>Subscription Plans:</strong> We offer various plans to access premium features.
              </li>
              <li>
                <strong>Billing:</strong> By subscribing, you authorize recurring charges and agree to provide accurate billing information.
              </li>
              <li>
                <strong>Cancellation:</strong> You may cancel your subscription at any time, with access maintained until the end of your billing cycle.
              </li>
              <li>
                <strong>Refund and Dispute Policy:</strong> All subscription fees are <u>non-refundable</u>. Please contact us within 30 days if you have concerns.
              </li>
            </ul>
          </article>

          <article>
            <h3>Content and Intellectual Property</h3>
            <ul>
              <li>
                <strong>User Content:</strong> You retain ownership of the content you submit. By submitting content, you grant us a license to use it in connection with our Services.
              </li>
              <li>
                <strong>Intellectual Property:</strong> All intellectual property rights in Catholicore are owned by us or our licensors.
              </li>
            </ul>
          </article>

          <article>
            <h3>Termination</h3>
            <p>
              We reserve the right to terminate or suspend your access to the Services immediately if you breach these Terms.
            </p>
          </article>

          <article>
            <h3>Disclaimers and Limitation of Liability</h3>
            <ul>
              <li>
                <strong>Disclaimers:</strong> Our Services are provided “as is” without warranties of any kind.
              </li>
              <li>
                <strong>Limitation of Liability:</strong> We are not liable for any indirect, incidental, or consequential damages.
              </li>
            </ul>
          </article>

          <article>
            <h3>Indemnification</h3>
            <p>
              You agree to indemnify Catholicore and its affiliates against any claims arising from your use of our Services.
            </p>
          </article>

          <article>
            <h3>Governing Law</h3>
            <p>
              These Terms are governed by the laws of Connecticut, without regard to its conflict of law provisions.
            </p>
          </article>

          <article>
            <h3>Changes to Terms</h3>
            <p>
              We reserve the right to modify these Terms at any time. Changes will be communicated by updating the “Last Updated” date.
            </p>
          </article>

          <article>
            <h3>Severability</h3>
            <p>
              If any provision of these Terms is unenforceable, the remaining provisions will remain in full force.
            </p>
          </article>

          <article>
            <h3>Entire Agreement</h3>
            <p>
              These Terms constitute the entire agreement between you and Catholicore regarding the use of our Services.
            </p>
          </article>

          <article>
            <h3>Contact Us</h3>
            <p>
              For any questions or concerns, please contact us at <a href="mailto:info@catholicore.com">info@catholicore.com</a>.
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default TermsService;
