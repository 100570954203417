import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ActionButton from '../Common/ActionButton';
import './UserForm.css';
import { useTranslation } from 'react-i18next';

import TextField from '../FormBuilder/Fields/TextField';
import EmailField from '../FormBuilder/Fields/EmailField';
import SelectField from '../FormBuilder/Fields/SelectField';
import Select from 'react-select';
import Tabs from '../Common/Tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconMap from '../Common/IconMap';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

const UserForm = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,

  // roles => each role: { id, name, icon, priority }
  roles,
  role,
  setRole,

  // If you want to prevent certain role changes based on priority, 
  // you could pass "currentUserPriority" and do checks here if desired.
  currentUserPriority = 999,

  disableRoleEdit = false,

  isMobile,
  onSubmit,
  isEditing,
  isSubmitting,
  membersData = [],
  users = [],

  editingMemberId = null,
}) => {
  const { t } = useTranslation();

  // If editing, skip the "members" tab
  const [selectedTab, setSelectedTab] = useState(isEditing ? 'manual' : 'manual');

  // For adding multiple new users from existing Members
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [membersRole, setMembersRole] = useState('');

  // For linking a single member if editing
  const [selectedSingleMember, setSelectedSingleMember] = useState(null);

  useEffect(() => {
    if (isEditing && editingMemberId) {
      setSelectedSingleMember(editingMemberId);
    }
  }, [isEditing, editingMemberId]);

  // Build a set of existing user emails so we don’t accidentally create duplicates
  const existingUserEmails = useMemo(
    () => new Set(users.map((u) => (u.email || '').toLowerCase())),
    [users]
  );

  /**
   * "memberOptionsMultiple": used for the “From Members” approach
   * Exclude any members that already have user accounts with the same email
   */
  const memberOptionsMultiple = useMemo(() => {
    if (!membersData || membersData.length === 0) return [];
    const filtered = membersData.filter((m) => {
      const memEmail = (m.email || '').toLowerCase();
      if (memEmail && existingUserEmails.has(memEmail)) {
        return false;
      }
      return true;
    });
    const sorted = [...filtered].sort((a, b) =>
      `${a.firstName || ''} ${a.lastName || ''}`.localeCompare(
        `${b.firstName || ''} ${b.lastName || ''}`
      )
    );
    const withEmail = sorted.filter((m) => m.email);
    const withoutEmail = sorted.filter((m) => !m.email);

    const withEmailOptions = withEmail.map((member) => ({
      value: member.id,
      label: `${member.firstName || ''} ${member.lastName || ''} (${member.email})`,
      data: member,
      isDisabled: false,
    }));
    const withoutEmailOptions = withoutEmail.map((member) => ({
      value: member.id,
      label: `${member.firstName || ''} ${member.lastName || ''} (Email is required)`,
      data: member,
      isDisabled: true,
    }));
    return [...withEmailOptions, ...withoutEmailOptions];
  }, [membersData, existingUserEmails]);

  /**
   * "memberOptionsSingle": used if we’re linking to a single member in "edit" mode
   */
  const memberOptionsSingle = useMemo(() => {
    if (!membersData || membersData.length === 0) return [];
    const filtered = membersData.filter((m) => {
      const memEmail = (m.email || '').toLowerCase();
      if (
        memEmail &&
        existingUserEmails.has(memEmail) &&
        memEmail !== (email || '').toLowerCase()
      ) {
        return false;
      }
      return true;
    });
    const sorted = [...filtered].sort((a, b) =>
      `${a.firstName || ''} ${a.lastName || ''}`.localeCompare(
        `${b.firstName || ''} ${b.lastName || ''}`
      )
    );
    return sorted.map((member) => ({
      value: member.id,
      label: `${member.firstName || ''} ${member.lastName || ''} (${member.email || 'No Email'})`,
      data: member,
    }));
  }, [membersData, existingUserEmails, email]);

  // Our custom styling for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '8px',
      borderColor: '#ddd',
      fontSize: '0.95rem',
      boxShadow: 'none',
      ':hover': {
        borderColor: 'var(--main-color)',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '0.95rem',
      backgroundColor: state.isSelected
        ? '#f0f0f0'
        : state.isFocused
        ? '#f0f0f0'
        : '#fff',
      color: state.isSelected ? '#fff' : '#333',
      opacity: state.isDisabled ? 0.5 : 1,
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  /**
   * Build the role select options. We do not show priorities in the label,
   * but you could disable them if the role's priority is <= currentUserPriority, etc.
   */
  const roleOptions = useMemo(() => {
    if (!Array.isArray(roles)) return [];
    return roles.map((r) => ({
      label: r.name,
      value: r.id,
      icon: r.icon,
      // If you want to block selecting a role that is the same or higher priority:
      // isDisabled: r.priority <= currentUserPriority,
      isDisabled: disableRoleEdit,
    }));
  }, [roles, disableRoleEdit, currentUserPriority]);

  // Format how each option looks (show an icon if available)
  const formatRoleOptionLabel = (option) => {
    const iconKey = option.icon && IconMap[option.icon] ? option.icon : null;
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
        {iconKey && (
          <FontAwesomeIcon
            icon={IconMap[iconKey]}
            style={{ color: 'var(--main-color)' }}
          />
        )}
        <span>{option.label}</span>
      </div>
    );
  };

  const formatMemberOptionLabel = (option) => {
    const member = option.data;
    const pictureURL = member.profilePicture;
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
        {pictureURL ? (
          <img
            src={pictureURL}
            alt={`${member.firstName || ''} ${member.lastName || ''}`}
            style={{
              backgroundColor: 'var(--main-color)',
              width: '24px',
              height: '24px',
              borderRadius: '50%',
            }}
          />
        ) : (
          <FontAwesomeIcon icon={faUserCircle} style={{ color: 'var(--main-color)' }} />
        )}
        <span>{option.label}</span>
      </div>
    );
  };

  // On form submission => pass data to parent
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditing) {
      onSubmit({
        mode: 'manual',
        firstName,
        lastName,
        email,
        role,
        memberId: selectedSingleMember || null,
      });
    } else {
      if (selectedTab === 'manual') {
        onSubmit({
          mode: 'manual',
          firstName,
          lastName,
          email,
          role,
        });
      } else {
        // from members
        onSubmit({
          mode: 'members',
          members: selectedMembers,
          role: membersRole,
        });
      }
    }
  };

  // Tabs => "Manual" vs "Members"
  const tabsDef = [
    { id: 'manual', label: t('userForm.manualTab', 'Manual') },
    { id: 'members', label: t('userForm.membersTab', 'From Members') },
  ];

  // The main submit button label and icon
  const submitButtonLabel = isSubmitting
    ? t('userForm.submitting', 'Submitting...')
    : isEditing
    ? t('userForm.updateButton', 'Update User')
    : selectedTab === 'manual'
    ? t('userForm.saveButton', 'Save User')
    : t('userForm.sendInvitation', 'Send Invitation');

  const submitButtonIcon = isSubmitting
    ? 'faSpinner'
    : !isEditing && selectedTab === 'members'
    ? 'faEnvelope'
    : 'faSave';

  return (
    <form className="users-modal-form enhanced-user-form" onSubmit={handleSubmit}>
      {/* Only show tabs if not editing */}
      {!isEditing && (
        <Tabs tabs={tabsDef} selectedTab={selectedTab} onTabSelect={setSelectedTab} />
      )}

      <div className="user-form-content">
        {(selectedTab === 'manual' || isEditing) && (
          <div className="user-form-manual">
            {/* First Name */}
            <div className="users-input-group">
              <TextField
                label={t('userForm.firstNameLabel', 'First Name')}
                name="firstName"
                value={firstName}
                onChange={setFirstName}
                required
                placeholder={t('userForm.firstNamePlaceholder', 'Enter first name')}
                size="full"
              />
            </div>

            {/* Last Name */}
            <div className="users-input-group">
              <TextField
                label={t('userForm.lastNameLabel', 'Last Name')}
                name="lastName"
                value={lastName}
                onChange={setLastName}
                required
                placeholder={t('userForm.lastNamePlaceholder', 'Enter last name')}
                size="full"
              />
            </div>

            {/* Email (read-only when editing) */}
            <div className="users-input-group">
              <EmailField
                label={t('userForm.emailLabel', 'Email')}
                name="email"
                value={email}
                onChange={setEmail}
                required
                disabled={isEditing}
                style={isEditing ? { backgroundColor: '#e9ecef' } : {}}
                placeholder={t('userForm.emailPlaceholder', 'Enter email')}
                size="full"
              />
            </div>

            {/* Role */}
            <div className="users-input-group">
              <label className="users-label">
                {t('userForm.roleLabel', 'Role')}
              </label>
              <SelectField
                name="role"
                value={role}
                onChange={setRole}
                required
                options={roleOptions}
                // If any role is disabled due to priority checks, 
                // it's set with isOptionDisabled in "roleOptions".
                isOptionDisabled={(option) => option.isDisabled}
                placeholder={t('userForm.rolePlaceholder', 'Select a role')}
                size="full"
                formatOptionLabel={formatRoleOptionLabel}
              />
            </div>

            {isEditing && (
              <div className="users-input-group">
                <label className="users-label">
                  {t('userForm.linkSingleMember', 'Link to Member')}
                </label>
                <Select
                  isClearable
                  options={memberOptionsSingle}
                  value={
                    memberOptionsSingle.find((opt) => opt.value === selectedSingleMember) ||
                    null
                  }
                  onChange={(selectedOption) => {
                    setSelectedSingleMember(selectedOption ? selectedOption.value : null);
                  }}
                  placeholder={t('userForm.selectSingleMemberPlaceholder', 'Select a member...')}
                  styles={customStyles}
                  formatOptionLabel={formatMemberOptionLabel}
                />
              </div>
            )}
          </div>
        )}

        {!isEditing && selectedTab === 'members' && (
          <div className="user-form-members">
            {membersData && membersData.length > 0 ? (
              <>
                <div className="users-input-group">
                  <label className="users-label">
                    {t('userForm.selectMultipleMembers', 'Select Members')}
                  </label>
                  <div className="select-wrapper">
                    <Select
                      isMulti
                      options={memberOptionsMultiple}
                      value={memberOptionsMultiple.filter((opt) =>
                        selectedMembers.includes(opt.value)
                      )}
                      onChange={(selectedOptions) => {
                        const values = selectedOptions
                          ? selectedOptions.map((o) => o.value)
                          : [];
                        setSelectedMembers(values);
                      }}
                      placeholder={t('userForm.selectMemberPlaceholder', 'Search members...')}
                      styles={customStyles}
                      formatOptionLabel={formatMemberOptionLabel}
                    />
                  </div>
                </div>

                <div className="users-input-group">
                  <label className="users-label">
                    {t('userForm.roleLabel', 'Role')}
                  </label>
                  <SelectField
                    name="membersRole"
                    value={membersRole}
                    onChange={setMembersRole}
                    required
                    options={roleOptions}
                    // also disabled if disableRoleEdit => but typically new
                    isOptionDisabled={(option) => option.isDisabled}
                    placeholder={t('userForm.rolePlaceholder', 'Select a role')}
                    size="full"
                    formatOptionLabel={formatRoleOptionLabel}
                  />
                </div>
              </>
            ) : (
              <p>{t('userForm.noMembersAvailable', 'No members available.')}</p>
            )}
          </div>
        )}

        {/* Submit button */}
        <div className="users-submit-button-container">
          <ActionButton
            type="submit"
            label={submitButtonLabel}
            text={submitButtonLabel}
            icon={submitButtonIcon}
            spin={isSubmitting}
            disabled={isSubmitting}
            isMobile={isMobile}
            colorType="primary"
            ariaLabel={
              isEditing
                ? t('userForm.updateButtonAria', 'Update this user')
                : selectedTab === 'manual'
                ? t('userForm.saveButtonAria', 'Save this user')
                : t('userForm.sendInvitationAria', 'Send Invitation')
            }
          />
        </div>
      </div>
    </form>
  );
};

UserForm.propTypes = {
  firstName: PropTypes.string.isRequired,
  setFirstName: PropTypes.func.isRequired,
  lastName: PropTypes.string.isRequired,
  setLastName: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,

  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      icon: PropTypes.string,
      priority: PropTypes.number,
    })
  ).isRequired,

  role: PropTypes.string.isRequired,
  setRole: PropTypes.func.isRequired,

  // Pass in your own priority to do further role disabling if needed
  currentUserPriority: PropTypes.number,
  disableRoleEdit: PropTypes.bool,

  isMobile: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,

  membersData: PropTypes.array,
  users: PropTypes.array,
  editingMemberId: PropTypes.string,
};

export default UserForm;
