// BibleVerse.jsx

import React, { useEffect, useState } from 'react';
import './BibleVerse.css';

const API_KEY = process.env.REACT_APP_FIREBASE_BIBLE_API_KEY;
const BIBLE_ID = '179568874c45066f-01';

/**
 * An array of verse references you want to display randomly.
 */
const VERSES = [
  'JER.29.11',
  'PSA.23.1',
  '1COR.4.4',
  'PHP.4.13',
  'JHN.3.16',
  'ROM.8.28',
  'ISA.41.10',
  'PSA.46.1',
  'GAL.5.22',
  'HEB.11.1',
  '2TI.1.7',
  '1COR.10.13',
  'PRO.22.6',
  'ISA.40.31',
  'JOS.1.9',
  'HEB.12.2',
  'MAT.11.28',
  'ROM.10.9',
  'PHP.2.3',
  'MAT.5.44',
];

function BibleVerse() {
  const [verseReference, setVerseReference] = useState('');
  const [verseText, setVerseText] = useState('');

  // Pick a random verse once per mount
  const verseIndex = Math.floor(Math.random() * VERSES.length);
  const verseID = VERSES[verseIndex];

  useEffect(() => {
    // If no API key is set, skip
    if (!API_KEY) return;

    async function fetchVerse() {
      try {
        const url = `https://api.scripture.api.bible/v1/bibles/${BIBLE_ID}/search?query=${verseID}`;
        const response = await fetch(url, {
          headers: {
            'api-key': API_KEY,
          },
        });
        const json = await response.json();

        // We expect something like json.data.passages[0].content
        const passages = json?.data?.passages;
        if (!passages || !passages.length) return;

        // Take the first passage
        const passage = passages[0];
        setVerseReference(passage.reference || '');

        // The full HTML in "content" (includes <span data-number="11">11</span> etc.)
        const contentHTML = passage.content || '';

        // 1) Remove everything up to '</span>'
        const afterSpan = contentHTML.split('</span>')[1] || '';
        // 2) Remove any remaining HTML tags
        const plainText = afterSpan.replace(/<[^>]+>/g, '').trim();

        setVerseText(plainText);
      } catch (err) {
        console.error('Error fetching verse:', err);
      }
    }

    // Single API call on mount
    fetchVerse();
  }, []);

  if (!verseReference && !verseText) {
    return (
      <div className="bible-verse fade-in">
        <p>Loading verse…</p>
      </div>
    );
  }

  return (
    <div className="bible-verse fade-in">
      <h2>Verse of the Day</h2>
      <figure className="bible-verse-figure">
        <blockquote className="bible-verse-text">{verseText}</blockquote>
        <figcaption className="bible-verse-citation">
          — <i>{verseReference}</i>
        </figcaption>
      </figure>
    </div>
  );
}

export default BibleVerse;
