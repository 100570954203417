// src/components/Common/Tabs.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './Tabs.css';

/**
 * Extended Tabs that allow a tabImage (icon) and tabColor (for active color)
 */
const Tabs = ({ tabs, selectedTab, onTabSelect }) => {
  return (
    <div className="tabs">
      {tabs.map((tab) => {
        const isActive = selectedTab === tab.id;
        const activeStyle =
          isActive && tab.tabColor
            ? { color: tab.tabColor, borderBottom: `2px solid ${tab.tabColor}` }
            : {};

        return (
          <div
            key={tab.id}
            className={`tab ${isActive ? 'active' : ''} ${tab.blink ? 'blink' : ''}`}
            style={activeStyle}
            onClick={() => onTabSelect(tab.id)}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                onTabSelect(tab.id);
              }
            }}
          >
            {tab.tabImage && (
              <img
                src={tab.tabImage}
                alt="tab-icon"
                className="tab-icon"
                style={{
                  marginRight: '8px',
                  width: '20px',
                  height: '20px',
                  objectFit: 'contain',
                }}
              />
            )}
            {tab.label}
          </div>
        );
      })}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      tabImage: PropTypes.string,
      tabColor: PropTypes.string,
      blink: PropTypes.bool, // New property to trigger blinking
    })
  ).isRequired,
  selectedTab: PropTypes.string.isRequired,
  onTabSelect: PropTypes.func.isRequired,
};

export default Tabs;
