import React, { useCallback, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './LogoUploadGroup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../Common/Spinner';
import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';

// Import Firebase and App Check modules
import firebase from 'firebase/compat/app';
import 'firebase/compat/app-check';

// ----- Helper Functions (from ImagePicker) -----

async function loadImageElement(fileOrBlob) {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(fileOrBlob);
    const img = new Image();
    img.onload = () => {
      URL.revokeObjectURL(url);
      resolve(img);
    };
    img.onerror = reject;
    img.src = url;
  });
}

async function centerCropAndResize(file, dimension) {
  const img = await loadImageElement(file);
  const { width, height } = img;
  const minSide = Math.min(width, height);
  const startX = (width - minSide) / 2;
  const startY = (height - minSide) / 2;

  const canvas = document.createElement('canvas');
  canvas.width = dimension;
  canvas.height = dimension;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    img,
    startX,
    startY,
    minSide,
    minSide,
    0,
    0,
    dimension,
    dimension
  );

  const blob = await new Promise((resolve) => {
    canvas.toBlob((b) => resolve(b), 'image/png', 1.0);
  });
  return blob;
}

async function compressBelowTargetKB(blob, targetKB, onProgress) {
  let currentBlob = blob;
  let currentSizeKB = currentBlob.size / 1024;

  const qualities = [0.8, 0.6, 0.4, 0.3, 0.2, 0.1];
  let pass = 0;

  while (currentSizeKB > targetKB && pass < qualities.length) {
    const options = {
      maxSizeMB: targetKB / 1024,
      initialQuality: qualities[pass],
      useWebWorker: true,
      onProgress,
    };
    try {
      const compressed = await imageCompression(currentBlob, options);
      currentBlob = compressed;
      currentSizeKB = compressed.size / 1024;
      console.log(`Compression pass #${pass + 1}, q=${qualities[pass]}, size=${Math.round(currentSizeKB)}KB`);
    } catch (err) {
      console.error(`Compression pass #${pass + 1} error:`, err);
      break;
    }
    pass++;
  }
  return currentBlob;
}

async function uploadImageImmediately(file, folderPath) {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('folderPath', folderPath);

  // Retrieve the App Check token from Firebase (force refresh with "true")
  const tokenResult = await firebase.appCheck().getToken(true);
  const appCheckToken = tokenResult.token;

  const res = await fetch('https://uploadimage-lgfph5hmwq-uc.a.run.app', {
    method: 'POST',
    headers: {
      'X-Firebase-AppCheck': appCheckToken,
    },
    body: formData,
  });
  if (!res.ok) {
    throw new Error(`uploadImageImmediately error: ${res.status}`);
  }
  const data = await res.json();
  return data.imageUrl;
}

// ----- LogoUploadGroup Component -----

const LogoUploadGroup = ({ setLogoFile, logoPreviewUrl, setLogoPreviewUrl }) => {
  const { t } = useTranslation();
  const { organizationData } = useContext(AppSettingsContext);

  // Local states for processing
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [compressionProgress, setCompressionProgress] = useState(0);

  // Configuration constants
  const maxFileSize = 5120; // in KB (~5MB)
  const maxFinalSizeKB = 500; // e.g. 500KB
  const maxDimension = 200; // final dimension (in pixels)

  // Process and upload the logo image using the same steps as ImagePicker
  const processAndUploadLogo = async (file) => {
    const fileSizeKB = file.size / 1024;
    if (fileSizeKB > maxFileSize) {
      alert(`File is ${Math.round(fileSizeKB)}KB, exceeds limit of ${maxFileSize}KB.`);
      return;
    }

    setLoading(true);
    setError('');
    setCompressionProgress(0);

    try {
      // 1) Center-crop and resize the image
      const croppedBlob = await centerCropAndResize(file, maxDimension);

      // 2) Compress if needed
      let finalBlob = croppedBlob;
      if (finalBlob.size / 1024 > maxFinalSizeKB) {
        finalBlob = await compressBelowTargetKB(finalBlob, maxFinalSizeKB, (p) => setCompressionProgress(p));
      }

      // 3) Generate a new file name with timestamp
      const now = new Date();
      const mm = String(now.getMonth() + 1).padStart(2, '0');
      const dd = String(now.getDate()).padStart(2, '0');
      const yy = String(now.getFullYear()).slice(-2);
      const hh = String(now.getHours()).padStart(2, '0');
      const min = String(now.getMinutes()).padStart(2, '0');
      const sec = String(now.getSeconds()).padStart(2, '0');
      const timestamp = `${mm}${dd}${yy}_${hh}${min}${sec}`;
      const extension = file.type.includes('png') ? '.png' : '.jpg';
      const newFileName = `logo_${timestamp}${extension}`;

      // 4) Convert the Blob into a File
      const finalFile = new File([finalBlob], newFileName, {
        type: file.type.includes('png') ? 'image/png' : 'image/jpeg',
        lastModified: Date.now(),
      });

      // 5) Build the folder path for logos (instead of members)
      const folderPath = organizationData
        ? `${organizationData.organizationId}/${organizationData.groupId}/logos`
        : 'logos';

      // 6) Upload the image
      const uploadedUrl = await uploadImageImmediately(finalFile, folderPath);

      // 7) Update preview and notify parent via the passed-in setters
      setLogoPreviewUrl(uploadedUrl);
      setLogoFile(uploadedUrl);
    } catch (err) {
      console.error('Error processing/uploading logo:', err);
      setError(t('logoUploadError', 'Failed to process image.'));
    } finally {
      setLoading(false);
      setCompressionProgress(0);
    }
  };

  // Handle image upload using react-dropzone
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;
      const file = acceptedFiles[0];
      processAndUploadLogo(file);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/png, image/jpeg',
    maxSize: 5 * 1024 * 1024, // 5MB in bytes
    onDrop,
  });

  return (
    <div className="form-group logo-upload-group">
      <label htmlFor="logoUpload">{t('logoUploadGroup.label', 'Upload Logo')}</label>
      <div
        {...getRootProps({ className: `dropzone ${isDragActive ? 'is-drag-active' : ''}` })}
        id="logoUpload"
      >
        <input {...getInputProps()} aria-label={t('logoUploadGroup.uploadAriaLabel', 'Upload your logo image')} />
        {isDragActive ? (
          <p>{t('logoUploadGroup.dropImage', 'Drop the image here...')}</p>
        ) : (
          <p>
            <FontAwesomeIcon icon={faCloudUploadAlt} />{' '}
            {t('logoUploadGroup.dragDrop', 'Drag and drop your logo here, or click to select one')}
          </p>
        )}
      </div>
      {loading && (
        <div className="logo-upload-loading">
          <Spinner size="50px" />
          {compressionProgress > 0 && compressionProgress < 100 && (
            <div className="compression-progress">
              {t('logoUploadGroup.compressing', 'Compressing...')} {Math.round(compressionProgress)}%
            </div>
          )}
        </div>
      )}
      {error && <p className="logo-upload-error">{error}</p>}
      {logoPreviewUrl && (
        <div className="logo-preview">
          <img src={logoPreviewUrl} alt={t('logoUploadGroup.logoPreviewAlt', 'Logo Preview')} />
        </div>
      )}
    </div>
  );
};

export default LogoUploadGroup;
