// src/contexts/OrganizationContext.js

import React, {
  createContext,
  useContext,
  useState,
} from 'react';
import { firestore } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

// Import AppSettingsContext to get the current org ID
import { AppSettingsContext } from './AppSettingsContext';

// Import Firebase and App Check modules
import firebase from 'firebase/compat/app';
import 'firebase/compat/app-check';

export const OrganizationContext = createContext();

export const OrganizationProvider = ({ children }) => {
  /**
   * If you have other states or logic, keep them here...
   */

  // NEW: We'll store the org’s billing info here
  const [billingData, setBillingData] = useState(null);

  // We read the current organizationId from AppSettingsContext
  const { organizationData } = useContext(AppSettingsContext);
  const currentOrgId = organizationData?.organizationId || '';

  /**
   * NEW: Fetches the billing info for the current organization doc in Firestore.
   * Example doc shape:
   *  {
   *    billing: {
   *      plan: "premium",
   *      renewalDate: "February 23, 2025 at 5:01:43 AM UTC-5"
   *    }
   *  }
   */
  const fetchOrganizationBilling = async () => {
    try {
      if (!currentOrgId) {
        console.warn('No organizationId found, skipping billing fetch');
        setBillingData(null);
        return;
      }

      // organization docs are in collection 'organization/{orgId}'
      const orgRef = doc(firestore, 'organization', currentOrgId);
      const orgSnap = await getDoc(orgRef);
      if (!orgSnap.exists()) {
        console.warn(`Organization doc ${currentOrgId} does not exist`);
        setBillingData(null);
        return;
      }

      const orgData = orgSnap.data();
      // e.g. orgData.billing = { plan: 'premium', renewalDate: '...' }
      const { billing = {} } = orgData;
      setBillingData(billing);
    } catch (error) {
      console.error('Error fetching organization billing:', error);
      setBillingData(null);
    }
  };

  /**
   * Existing function to fetch multiple organizations by IDs.
   * (From your original code.)
   */
  const fetchOrganizationsByIds = async (orgIds) => {
    if (!orgIds || orgIds.length === 0) return {};

    const orgDataObj = {};
    for (const orgId of orgIds) {
      const orgRef = doc(firestore, 'organization', orgId);
      const orgSnap = await getDoc(orgRef);
      orgDataObj[orgId] = orgSnap.exists()
        ? orgSnap.data()
        : { name: 'Unknown Org' };
    }
    return orgDataObj;
  };

  /**
   * Example function to create the organization, groups, roles, etc.
   * (Already in your original code.)
   */
  const createOrganizationWithUser = async (payload) => {
    try {
      // Retrieve the App Check token (force refresh by passing true)
      const tokenResult = await firebase.appCheck().getToken(true);
      const appCheckToken = tokenResult.token;

      // This time we do a JSON POST, not multipart/form-data
      const signUpResponse = await fetch('https://sign-up-lgfph5hmwq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Firebase-AppCheck': appCheckToken,
        },
        body: JSON.stringify(payload),
      });

      if (!signUpResponse.ok) {
        const errorData = await signUpResponse.text();
        throw new Error(`Failed to sign up: ${errorData || 'Unknown error'}`);
      }

      const { uid, organizationId, groupId, profilePictureUrl } = await signUpResponse.json();
      return { uid, organizationId, groupId, profilePictureUrl };
    } catch (error) {
      console.error('Error during sign-up:', error);
      throw error;
    }
  };

  return (
    <OrganizationContext.Provider
      value={{
        // Existing methods:
        createOrganizationWithUser,
        fetchOrganizationsByIds,

        // NEW billing info & method
        billingData,           // e.g. { plan: 'premium', renewalDate: '...' }
        fetchOrganizationBilling,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
