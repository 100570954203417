// src/components/Subscription/SubscriptionPage.jsx
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Import firebase to use App Check (like in ImagePicker)
import firebase from 'firebase/compat/app';
import 'firebase/compat/app-check';

// Import the OrganizationContext to get the billing info
import { OrganizationContext } from '../../contexts/OrganizationContext';

import CustomModal from '../Common/CustomModal';
import Spinner from '../Common/Spinner';

import CatholicoreLogo from '../../assets/CatholicoreLogo.png';
import './SubscriptionPage.css';

export default function SubscriptionPage() {
  // 1) Read billingData and fetch method
  const { billingData, fetchOrganizationBilling } = useContext(OrganizationContext);

  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState('');

  // For modals
  const [showSpinnerModal, setShowSpinnerModal] = useState(false);
  const [showEnterpriseModal, setShowEnterpriseModal] = useState(false);

  // For the enterprise contact form
  const [enterpriseForm, setEnterpriseForm] = useState({
    name: '',
    email: '',
    message: '',
  });

  // We'll store a **string** version of the renewal date after parsing
  const [parsedRenewalDate, setParsedRenewalDate] = useState('');

  const navigate = useNavigate();

  // 2) On mount, load the org’s billing
  useEffect(() => {
    fetchOrganizationBilling();
    // eslint-disable-next-line
  }, []);

  // 3) If redirect from Stripe Checkout
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }
    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  /**
   * Parse billingData.renewalDate into a user-friendly string.
   */
  useEffect(() => {
    if (billingData?.renewalDate) {
      if (typeof billingData.renewalDate?.toDate === 'function') {
        const dateObj = billingData.renewalDate.toDate();
        setParsedRenewalDate(
          dateObj.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          })
        );
      } else {
        const maybeDate = new Date(billingData.renewalDate);
        if (!isNaN(maybeDate.getTime())) {
          setParsedRenewalDate(
            maybeDate.toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            })
          );
        } else {
          setParsedRenewalDate(billingData.renewalDate);
        }
      }
    } else {
      setParsedRenewalDate('');
    }
  }, [billingData]);

  const closeSpinner = () => {
    setShowSpinnerModal(false);
  };

  const handleEnterpriseClick = () => {
    setShowEnterpriseModal(true);
  };

  // Enterprise inquiry uses fetch with App Check token.
  const handleEnterpriseSubmit = async (e) => {
    e.preventDefault();
    try {
      const tokenResult = await firebase.appCheck().getToken(true);
      const appCheckToken = tokenResult.token;
      await fetch('https://sendemail-lgfph5hmwq-uc.a.run.app', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'X-Firebase-AppCheck': appCheckToken
        },
        body: JSON.stringify({
          toAddress: 'info@catholicore.com',
          subject: `Enterprise Inquiry from ${enterpriseForm.name}`,
          content: `
            <p><strong>Name:</strong> ${enterpriseForm.name}</p>
            <p><strong>Email:</strong> ${enterpriseForm.email}</p>
            <p><strong>Message:</strong></p>
            <p>${enterpriseForm.message}</p>
          `,
        }),
      });
    } catch (err) {
      console.error('Error sending enterprise inquiry:', err);
    }
    setEnterpriseForm({ name: '', email: '', message: '' });
    setShowEnterpriseModal(false);
    alert('Thank you! We will contact you soon.');
  };

  const currentPlan = billingData?.plan || '';

  return (
    <div className="subscription-split-container">
      {/* LEFT PANEL */}
      <div className="subscription-left-panel">
        <button
          className="subscription-back-button"
          onClick={() => navigate(-1)}
          aria-label="Go Back"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <div className="subscription-left-logo">
          <img src={CatholicoreLogo} alt="Catholicore Logo" />
        </div>
        <div className="subscription-left-content">
          {success && sessionId ? (
            <SuccessDisplay sessionId={sessionId} />
          ) : message ? (
            <Message message={message} />
          ) : (
            <PlanCards
              onShowSpinner={() => setShowSpinnerModal(true)}
              onEnterpriseClick={handleEnterpriseClick}
              currentPlan={currentPlan}
              renewalDate={parsedRenewalDate}
            />
          )}
        </div>
      </div>
      {/* RIGHT PANEL */}
      <div className="subscription-right-panel">
        <div className="subscription-right-content">
          <h3>Need Help Choosing a Plan?</h3>
          <p>
            Our team is here to help you decide which plan works best
            for your parish. We also offer custom solutions for large
            or unique organizations.
          </p>
        </div>
      </div>
      {/* Spinner Modal */}
      <CustomModal
        show={showSpinnerModal}
        onClose={closeSpinner}
        closable={false}
        title="Preparing Secure Checkout..."
      >
        <div style={{ textAlign: 'center', padding: '2rem' }}>
          <Spinner size="50px" />
          <p style={{ marginTop: '1rem', lineHeight: '1.5' }}>
            Please wait while we securely connect you to our Payment Portal. This will only take a moment.
          </p>
        </div>
      </CustomModal>
      {/* Enterprise Contact Form Modal */}
      <CustomModal
        show={showEnterpriseModal}
        onClose={() => setShowEnterpriseModal(false)}
        title="Enterprise Inquiry"
      >
        <div className="enterprise-modal-body">
          <p>Please tell us more about your parish or organization's needs.</p>
          <form onSubmit={handleEnterpriseSubmit} className="enterprise-contact-form">
            <label htmlFor="ent-name">Name</label>
            <input
              id="ent-name"
              type="text"
              required
              value={enterpriseForm.name}
              onChange={(e) => setEnterpriseForm((prev) => ({ ...prev, name: e.target.value }))}
            />
            <label htmlFor="ent-email">Email</label>
            <input
              id="ent-email"
              type="email"
              required
              value={enterpriseForm.email}
              onChange={(e) => setEnterpriseForm((prev) => ({ ...prev, email: e.target.value }))}
            />
            <label htmlFor="ent-message">Message</label>
            <textarea
              id="ent-message"
              rows={4}
              required
              value={enterpriseForm.message}
              onChange={(e) => setEnterpriseForm((prev) => ({ ...prev, message: e.target.value }))}
            />
            <button type="submit" className="enterprise-submit-btn">
              Send
            </button>
          </form>
        </div>
      </CustomModal>
    </div>
  );
}

/**
 * PlanCards component
 *
 * The onSubmit handler now retrieves the App Check token and posts the form data via fetch
 * to the create_checkout_session endpoint. That endpoint returns JSON with the checkout URL,
 * and then the client code redirects the browser.
 */
function PlanCards({ onShowSpinner, onEnterpriseClick, currentPlan, renewalDate }) {
  const handleSubmit = async (e) => {
    e.preventDefault();
    onShowSpinner?.();
    try {
      // Retrieve App Check token
      const tokenResult = await firebase.appCheck().getToken(true);
      const appCheckToken = tokenResult.token;
      // Build FormData from the form element
      const formData = new FormData(e.target);
      // POST to create_checkout_session with the App Check header
      const response = await fetch('https://create-checkout-session-lgfph5hmwq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'X-Firebase-AppCheck': appCheckToken
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`Checkout session error: ${response.status}`);
      }
      // Parse JSON response for the URL
      const data = await response.json();
      if (data && data.url) {
        window.location.href = data.url;
      } else {
        throw new Error('No redirect URL returned.');
      }
    } catch (err) {
      console.error('Error during checkout session creation:', err);
    }
  };

  const standardPlanClasses = `plan-card most-popular ${
    currentPlan === 'standard' ? 'selected-plan' : !currentPlan ? 'shake' : ''
  }`;

  return (
    <>
      <h2 className="subscription-title">Pricing</h2>
      <p className="subscription-subtitle">
        {renewalDate
          ? `Your plan renews on ${renewalDate}.`
          : 'Experience the essential plan options for your parish size.'}
      </p>
      <div className="plan-cards-container">
        {/* FREE PLAN */}
        <div className={`plan-card ${currentPlan === 'free' ? 'selected-plan' : ''}`}>
          <h3 className="plan-name">
            Free {currentPlan === 'free' && <span>(Current Plan)</span>}
          </h3>
          <p className="plan-price">$0/month</p>
          <ul className="plan-features">
            <li>Up to 15 Members</li>
            <li>Basic Event Management</li>
            <li>SMS/MMS: Pay-as-you-go</li>
          </ul>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="lookup_key" value="free_plan_lookup_key" />
            <button type="submit" className="plan-button" disabled={currentPlan === 'free'}>
              {currentPlan === 'free' ? 'Selected Plan' : 'Try for Free'}
            </button>
          </form>
        </div>
        {/* BASIC PLAN */}
        <div className={`plan-card ${currentPlan === 'basic' ? 'selected-plan' : ''}`}>
          <h3 className="plan-name">
            Basic {currentPlan === 'basic' && <span>(Current Plan)</span>}
          </h3>
          <p className="plan-price">$29/month</p>
          <ul className="plan-features">
            <li>Manage up to 100 Members</li>
            <li>500 monthly message credits</li>
            <li>Essential Event Management</li>
            <li>Create up to 3 Groups</li>
          </ul>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="lookup_key" value="basic_plan_lookup_key" />
            <button type="submit" className="plan-button" disabled={currentPlan === 'basic'}>
              {currentPlan === 'basic' ? 'Selected Plan' : 'Get Started'}
            </button>
          </form>
        </div>
        {/* STANDARD PLAN (MOST POPULAR) */}
        <div className={standardPlanClasses}>
          <div className="badge">Most Popular</div>
          <h3 className="plan-name">
            Standard {currentPlan === 'standard' && <span>(Current Plan)</span>}
          </h3>
          <p className="plan-price">$79/month</p>
          <ul className="plan-features">
            <li>Manage up to 300 Members</li>
            <li>1,500 monthly message credits</li>
            <li>Full Event Management</li>
            <li>Scheduled SMS & Email Notifications</li>
            <li>Reports & Customer Support</li>
          </ul>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="lookup_key" value="standard_plan_lookup_key" />
            <button type="submit" className="plan-button" disabled={currentPlan === 'standard'}>
              {currentPlan === 'standard' ? 'Selected Plan' : 'Choose Standard'}
            </button>
          </form>
        </div>
        {/* PREMIUM PLAN */}
        <div className={`plan-card ${currentPlan === 'premium' ? 'selected-plan' : ''}`}>
          <h3 className="plan-name">
            Premium {currentPlan === 'premium' && <span>(Current Plan)</span>}
          </h3>
          <p className="plan-price">$149/month</p>
          <ul className="plan-features">
            <li>Manage up to 1,000 Members</li>
            <li>5,000 monthly message credits</li>
            <li>Advanced Analytics & Custom Reporting</li>
            <li>Priority Support</li>
            <li>All Standard Features</li>
          </ul>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="lookup_key" value="premium_plan_lookup_key" />
            <button type="submit" className="plan-button" disabled={currentPlan === 'premium'}>
              {currentPlan === 'premium' ? 'Selected Plan' : 'Go Premium'}
            </button>
          </form>
        </div>
        {/* ENTERPRISE PLAN */}
        <div className={`plan-card ${currentPlan === 'enterprise' ? 'selected-plan' : ''}`}>
          <h3 className="plan-name">
            Enterprise {currentPlan === 'enterprise' && <span>(Current Plan)</span>}
          </h3>
          <p className="plan-price">Contact Us</p>
          <ul className="plan-features">
            <li>Unlimited Members</li>
            <li>All Premium Features</li>
            <li>Custom SMS/MMS Allowances</li>
            <li>Custom Integrations & Solutions</li>
            <li>Dedicated Priority Support</li>
          </ul>
          <button className="plan-button" onClick={onEnterpriseClick} disabled={currentPlan === 'enterprise'}>
            {currentPlan === 'enterprise' ? 'Selected Plan' : 'Contact Us'}
          </button>
        </div>
      </div>
    </>
  );
}

function SuccessDisplay({ sessionId }) {
  return (
    <>
      <h2>Subscription successful!</h2>
      <p>Your subscription is now active.</p>
      <form action="https://create-portal-session-lgfph5hmwq-uc.a.run.app" method="POST">
        <input type="hidden" id="session-id" name="session_id" value={sessionId} />
        <button id="checkout-and-portal-button" type="submit" className="subscription-button">
          Manage your billing
        </button>
      </form>
    </>
  );
}

function Message({ message }) {
  return <p>{message}</p>;
}
