import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import './MessageSender.css';
import MessageInput from './MessageInput';
import ImageUpload from './ImageUpload';
import ImagePreview from './ImagePreview';
import ActionButton from '../Common/ActionButton';
import ErrorMessage from './ErrorMessage';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { DataContext } from '../../DataContext';
import { MessageContext } from '../../contexts/MessageContext';
import { UserContext } from '../../contexts/UserContext'; // <-- for hasPermission
import { TeamContext } from '../../contexts/TeamContext';
import SelectField from '../FormBuilder/Fields/SelectField';
import TextField from '../FormBuilder/Fields/TextField';
import DefaultProfilePicture from '../../assets/UserIcon.png';
import ToastContainer from '../Common/ToastContainer';
import CustomModal from '../Common/CustomModal';
import DatePicker from '../FormBuilder/Fields/DatePicker';
import TimePicker from '../FormBuilder/Fields/TimePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconMap from '../Common/IconMap';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';

const MessageSender = (props) => {
  const { t } = useTranslation();
  const { onClose, single, recipient, showHistory, onlyScheduled } = props;

  const { teams, groupsData, useResponses } = useContext(DataContext);
  const {organizationData, hasPermission } = useContext(AppSettingsContext);
  const { messages, isMessagesLoading, isMessagesError, addMessage, editMessage, deleteScheduledMessage } = useContext(MessageContext);
  const { currentUser } = useContext(UserContext);
  const { teamTypes } = useContext(TeamContext);

  // -------------------- Permission Checks --------------------
  const canSendMessage = hasPermission('communications', 'sendMessage');
  const canScheduleMessage = hasPermission('communications', 'scheduleMessage');
  const canViewHistory = hasPermission('communications', 'viewHistory');
  // If user can do neither, the Compose tab is effectively useless
  const canCompose = canSendMessage || canScheduleMessage;

  const { data: responses = [] } = useResponses('membersForm');
  const members = responses.map((response) => {
    const member = {};
    response.fields.forEach((field) => {
      member[field.id] = field.value;
    });
    member.id = response.id;
    member.profilePicture = member.profilePicture || DefaultProfilePicture;
    member.groups = member.groups ? member.groups : [];
    return member;
  });

  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const [recipientType, setRecipientType] = useState('All');
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const [toasts, setToasts] = useState([]);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [totalMessages, setTotalMessages] = useState(0);
  const [currentRecipient, setCurrentRecipient] = useState('');

  const [isScheduled, setIsScheduled] = useState(onlyScheduled);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [scheduledTime, setScheduledTime] = useState(null);

  const [activeTab, setActiveTab] = useState('Compose');
  const [expandedMessages, setExpandedMessages] = useState([]);
  const [showRecipientsForMessage, setShowRecipientsForMessage] = useState(null);

  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editingTitle, setEditingTitle] = useState('');
  const [editingContent, setEditingContent] = useState('');
  const [editingDate, setEditingDate] = useState(null);
  const [editingTime, setEditingTime] = useState(null);

  const formatDate = (date) => {
    if (!date) return '';
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    let hours = date.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };

  const handleScheduleToggle = () => {
    if (!onlyScheduled && canScheduleMessage) {
      setIsScheduled(!isScheduled);
    }
  };

  const toggleMessageExpand = (messageId) => {
    setExpandedMessages((prev) =>
      prev.includes(messageId) ? prev.filter((id) => id !== messageId) : [...prev, messageId]
    );
  };

  const showToast = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  const removeImage = () => {
    setImage(null);
    setImagePreviewUrl(null);
  };

  useEffect(() => {
    let objectUrl;
    if (image) {
      objectUrl = URL.createObjectURL(image);
      setImagePreviewUrl(objectUrl);
    }
    return () => {
      if (objectUrl) URL.revokeObjectURL(objectUrl);
    };
  }, [image]);

  // Create TeamType options
  const teamTypeOptions = teamTypes.map((type) => ({
    value: `TeamType_${type.id}`,
    label: type.name,
  }));

  // Create recipientType options
  const baseRecipientTypeOptions = [
    { value: 'All', label: t('messageSender.sendToAll', 'All Members') },
    ...(organizationData && organizationData.groupId === organizationData.organizationId
      ? [{ value: 'Groups', label: t('messageSender.sendToGroups', 'Groups') }]
      : []),
    ...teamTypeOptions,
    { value: 'Members', label: t('messageSender.sendToMembers', 'Individual Members') },
  ];

  const recipientTypeOptions = [...baseRecipientTypeOptions];

  // Check if the selected recipientType is one of the TeamTypes
  const selectedTeamType = recipientType.startsWith('TeamType_')
    ? recipientType.split('_')[1]
    : null;

  // Filter teams by selected TeamType
  const filteredTeams = selectedTeamType
    ? teams.filter((team) => team.teamTypeId === selectedTeamType)
    : [];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      color: state.isSelected ? '#fff' : '#333',
      backgroundColor: state.isSelected
        ? 'var(--main-color)'
        : state.isFocused
        ? '#f0f0f0'
        : '#fff',
    }),
  };

  const formatOptionLabel = ({ label, profilePicture }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {profilePicture ? (
        <img
          src={profilePicture}
          alt={label}
          style={{
            width: 30,
            height: 30,
            backgroundColor: 'var(--main-color)',
            borderRadius: '50%',
            marginRight: 10,
          }}
        />
      ) : (
        <div
          style={{
            width: 30,
            height: 30,
            borderRadius: '50%',
            backgroundColor: '#ccc',
            marginRight: 10,
          }}
        ></div>
      )}
      <span>{label}</span>
    </div>
  );

  const handleSelectAllGroups = () => {
    const allGroups = groupsData.map((group) => ({
      value: group.id,
      label: group.name || group.groupName,
      profilePicture: group.profilePicture || DefaultProfilePicture,
    }));
    setSelectedGroups(allGroups);
  };

  const handleSelectAllTeams = () => {
    const allTeams = filteredTeams.map((team) => ({
      value: team.id,
      label: team.name,
    }));
    setSelectedTeams(allTeams);
  };

  // ------------------------------------------------
  // Submit Compose
  // ------------------------------------------------
  const handleSubmit = async (event) => {
    event.preventDefault();
    // If user can't send or schedule, block entirely
    if (!canCompose) {
      setError(t('messageSender.noPermissionToCompose', 'You do not have permission to send or schedule messages.'));
      return;
    }

    setIsLoading(true);
    setError('');

    if (isScheduled && (!scheduledDate || !scheduledTime)) {
      setError(t('messageSender.invalidScheduledDateTime', 'Please select a valid date and time.'));
      setIsLoading(false);
      return;
    }

    let scheduledDateTime;
    if (isScheduled) {
      const now = new Date();
      scheduledDateTime = new Date(
        scheduledDate.getFullYear(),
        scheduledDate.getMonth(),
        scheduledDate.getDate(),
        scheduledTime.getHours(),
        scheduledTime.getMinutes(),
        0,
        0
      );

      if (scheduledDateTime <= now) {
        setError(t('messageSender.invalidScheduledDateTime', 'Please select a future date and time.'));
        setIsLoading(false);
        return;
      }
      // Also ensure user can actually schedule
      if (!canScheduleMessage) {
        setError(t('messageSender.noSchedulePermission', 'You do not have permission to schedule messages.'));
        setIsLoading(false);
        return;
      }
    } else {
      // Make sure user can actually send
      if (!canSendMessage) {
        setError(t('messageSender.noSendPermission', 'You do not have permission to send messages.'));
        setIsLoading(false);
        return;
      }
    }

    let recipients = [];
    if (single && recipient) {
      recipients = [
        {
          phoneNumber: recipient,
          name: recipient,
        },
      ];
    } else {
      if (recipientType === 'All') {
        recipients = members
          .filter((member) => member.phoneNumber)
          .map((member) => ({
            phoneNumber: member.phoneNumber,
            name: `${member.firstName} ${member.lastName}`,
          }));
      } else if (recipientType === 'Groups') {
        const selectedGroupIds = selectedGroups.map((group) => group.value);
        recipients = members
          .filter(
            (member) =>
              member.groups && member.groups.some((group) => selectedGroupIds.includes(group.groupId))
          )
          .filter((member) => member.phoneNumber)
          .map((member) => ({
            phoneNumber: member.phoneNumber,
            name: `${member.firstName} ${member.lastName}`,
          }));
      } else if (selectedTeamType) {
        const selectedTeamIds = selectedTeams.map((team) => team.value);
        const selectedTeamsData = teams.filter((team) => selectedTeamIds.includes(team.id));
        const recipientMap = new Map();
        selectedTeamsData.forEach((team) => {
          (team.membersData || []).forEach((member) => {
            if (member.phoneNumber) {
              const key = member.phoneNumber;
              if (!recipientMap.has(key)) {
                recipientMap.set(key, {
                  phoneNumber: member.phoneNumber,
                  name: `${member.firstName} ${member.lastName}`,
                });
              }
            }
          });
        });
        recipients = Array.from(recipientMap.values());
      } else if (recipientType === 'Members') {
        const selectedMemberIds = selectedMembers.map((member) => member.value);
        recipients = members
          .filter((member) => selectedMemberIds.includes(member.id))
          .filter((member) => member.phoneNumber)
          .map((member) => ({
            phoneNumber: member.phoneNumber,
            name: `${member.firstName} ${member.lastName}`,
          }));
      }
    }

    if (recipients.length === 0) {
      setError(t('messageSender.noRecipients', 'No recipients found.'));
      setIsLoading(false);
      return;
    }

    const result = await addMessage({
      title,
      message,
      image,
      recipients,
      recipientType,
      selectedGroups: selectedGroups.map((g) => g.value),
      selectedTeams: selectedTeams.map((t) => t.value),
      selectedMembers: selectedMembers.map((m) => m.value),
      isScheduled,
      scheduledDateTime,
    });

    if (result.error) {
      setError(result.error);
    } else {
      if (result.isScheduled) {
        showToast(t('messageSender.scheduledSuccess', 'Message scheduled successfully'), 'success');
      } else {
        showToast(t('messageSender.queuedSuccess', 'Message queued for sending'), 'success');
      }
      setMessage('');
      setTitle('');
      setImage(null);
      setImagePreviewUrl(null);
      setIsScheduled(onlyScheduled);
      setScheduledDate(null);
      setScheduledTime(null);
      if (onClose) onClose();
    }

    setIsLoading(false);
  };

  // Sort messages (latest first)
  const sortedMessages = messages.slice().sort((a, b) => {
    const dateA = a.createdAt ? a.createdAt.getTime() : 0;
    const dateB = b.createdAt ? b.createdAt.getTime() : 0;
    return dateB - dateA;
  });

  const toggleRecipientsList = (messageId) => {
    setShowRecipientsForMessage((prev) => (prev === messageId ? null : messageId));
  };

  // Main button text
  const mainButtonText = isLoading
    ? t('messageSender.sending', 'Sending...')
    : isScheduled
    ? t('messageSender.scheduleMessage', 'Schedule Message')
    : t('messageSender.sendMessage', 'Send Message');

  // Editing scheduled
  const startEditingMessage = (msg) => {
    setEditingMessageId(msg.id);
    setEditingTitle(msg.title || '');
    setEditingContent(msg.content || '');
    if (msg.scheduledDateTime) {
      setEditingDate(msg.scheduledDateTime);
      setEditingTime(msg.scheduledDateTime);
    } else {
      setEditingDate(null);
      setEditingTime(null);
    }
  };

  const cancelEditingMessage = () => {
    setEditingMessageId(null);
    setEditingTitle('');
    setEditingContent('');
    setEditingDate(null);
    setEditingTime(null);
    setError('');
  };

  const saveEditedMessage = async (msg) => {
    const confirmed = window.confirm(t('messageSender.confirmEdit', 'Are you sure you want to save changes?'));
    if (!confirmed) return;

    let newScheduledDateTime = msg.scheduledDateTime;
    if (msg.messageType === 'scheduled' && editingDate && editingTime) {
      const newDateTime = new Date(
        editingDate.getFullYear(),
        editingDate.getMonth(),
        editingDate.getDate(),
        editingTime.getHours(),
        editingTime.getMinutes(),
        0,
        0
      );
      if (newDateTime <= new Date()) {
        showToast(t('messageSender.futureDateRequired', 'Please select a future date and time.'), 'error');
        return;
      }
      newScheduledDateTime = newDateTime;
    }

    try {
      setIsLoading(true);
      await editMessage(msg.id, {
        title: editingTitle,
        content: editingContent,
        scheduledDateTime: newScheduledDateTime,
      });
      showToast(t('messageSender.editSuccess', 'Message edited successfully'), 'success');
      cancelEditingMessage();
    } catch (error) {
      console.error('Error editing message:', error);
      showToast(t('messageSender.errorEditingMessage', 'Error editing message'), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteScheduledMessage = async (msgId) => {
    const confirmed = window.confirm(t('messageSender.confirmDelete', 'Are you sure you want to delete this scheduled message?'));
    if (!confirmed) return;

    setIsLoading(true);
    setError('');
    try {
      await deleteScheduledMessage(msgId);
      showToast(t('messageSender.deleteSuccess', 'Scheduled message deleted successfully'), 'success');
    } catch (error) {
      console.error('Error deleting scheduled message:', error);
      showToast(t('messageSender.deleteError', 'Error deleting scheduled message'), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const now = new Date();

  return (
    <div className="message-container">
      <div className="message-recovery-container">
        <h2 className="messageTitle">{t('messageSender.title', 'Send Message')}</h2>

        {/* If showHistory === true, we normally show 2 tabs: Compose & History. 
            Now we also check permissions:
               - Compose tab only if canCompose
               - History tab only if canViewHistory
        */}
        {showHistory ? (
          <div className="message-sender-tabs">
            <div className="tabs">
              {canCompose && (
                <button
                  className={activeTab === 'Compose' ? 'active' : ''}
                  onClick={() => setActiveTab('Compose')}
                >
                  {t('messageSender.composeTab', 'Compose')}
                </button>
              )}
              {canViewHistory && (
                <button
                  className={activeTab === 'History' ? 'active' : ''}
                  onClick={() => setActiveTab('History')}
                >
                  {t('messageSender.historyTab', 'History')}
                </button>
              )}
            </div>

            {/* Compose Tab */}
            {activeTab === 'Compose' && canCompose ? (
              <form onSubmit={handleSubmit} className="login-form">
                {/* If we can't compose, this won't render, but let's keep the code as is */}
                {!single && (
                  <>
                    <SelectField
                      label={t('messageSender.recipientType', 'Send To')}
                      name="recipientType"
                      value={recipientType}
                      onChange={(value) => setRecipientType(value)}
                      options={recipientTypeOptions}
                      required
                    />
                    {recipientType === 'Groups' && (
                      <div className="input-group">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <label>{t('messageSender.selectGroups', 'Select Groups')}</label>
                          <ActionButton
                            text={t('messageSender.selectAll', 'Select All')}
                            onClick={handleSelectAllGroups}
                            icon="faCheck"
                            colorType="secondary"
                            size="small"
                          />
                        </div>
                        <Select
                          isMulti
                          options={groupsData.map((group) => ({
                            value: group.id,
                            label: group.name || group.groupName,
                            profilePicture: group.profilePicture || DefaultProfilePicture,
                          }))}
                          value={selectedGroups}
                          onChange={(selectedOptions) => setSelectedGroups(selectedOptions)}
                          styles={customStyles}
                          formatOptionLabel={formatOptionLabel}
                          classNamePrefix="react-select"
                        />
                      </div>
                    )}

                    {selectedTeamType && (
                      <div className="input-group">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <label>{t('messageSender.selectTeams', 'Select Teams')}</label>
                          <ActionButton
                            text={t('messageSender.selectAll', 'Select All')}
                            onClick={handleSelectAllTeams}
                            icon="faCheck"
                            colorType="secondary"
                            size="small"
                          />
                        </div>
                        <Select
                          isMulti
                          options={filteredTeams.map((team) => ({
                            value: team.id,
                            label: team.name,
                          }))}
                          value={selectedTeams}
                          onChange={(selectedOptions) => setSelectedTeams(selectedOptions || [])}
                          classNamePrefix="react-select"
                        />
                      </div>
                    )}

                    {recipientType === 'Members' && (
                      <div className="input-group">
                        <label>{t('messageSender.selectMembers', 'Select Members')}</label>
                        <Select
                          isMulti
                          options={members.map((member) => ({
                            value: member.id,
                            label: `${member.firstName} ${member.lastName}`,
                            profilePicture: member.profilePicture || DefaultProfilePicture,
                          }))}
                          value={selectedMembers}
                          onChange={(selectedOptions) => setSelectedMembers(selectedOptions)}
                          styles={customStyles}
                          formatOptionLabel={formatOptionLabel}
                          classNamePrefix="react-select"
                        />
                      </div>
                    )}
                  </>
                )}
                {isScheduled && (
                  <TextField
                    label={t('messageSender.optionalTitle', 'Title (optional)')}
                    name="title"
                    value={title}
                    onChange={setTitle}
                    placeholder={t('messageSender.titlePlaceholder', 'Enter a title for this message')}
                  />
                )}
                <div className="input-group">
                  <MessageInput message={message} setMessage={setMessage} />
                  <ImageUpload setImage={setImage} />
                  {imagePreviewUrl && <ImagePreview imagePreviewUrl={imagePreviewUrl} removeImage={removeImage} />}
                </div>
                {isScheduled && (
                  <div className="input-group date-time-group">
                    <DatePicker
                      label={t('messageSender.selectDate', 'Select Date')}
                      name="scheduledDate"
                      value={scheduledDate}
                      onChange={setScheduledDate}
                      placeholder={t('messageSender.datePlaceholder', 'MM/DD/YYYY')}
                    />
                    <TimePicker
                      label={t('messageSender.selectTime', 'Select Time')}
                      name="scheduledTime"
                      value={scheduledTime}
                      onChange={setScheduledTime}
                      placeholder={t('messageSender.timePlaceholder', 'HH:MM')}
                    />
                  </div>
                )}
                {error && <ErrorMessage error={error} />}
                <div className="action-buttons">
                  {/* If user can't send or schedule, hide this button entirely */}
                  <ActionButton
                    type="submit"
                    text={mainButtonText}
                    icon="faPaperPlane"
                    disabled={isLoading}
                    size="large"
                    fullWidth={true}
                    colorType="primary"
                    style={{ display: canCompose ? 'inline-block' : 'none' }}
                    // Only allow schedule toggle if canScheduleMessage is true
                    split={!onlyScheduled && canScheduleMessage}
                    onSplitClick={onlyScheduled ? undefined : handleScheduleToggle}
                    splitIcon="faClock"
                  />
                </div>
              </form>
            ) : null}

            {/* History Tab */}
            {activeTab === 'History' && canViewHistory ? (
              <div className="message-history">
                {isMessagesLoading ? (
                  <p>{t('messageSender.loadingMessages', 'Loading messages...')}</p>
                ) : isMessagesError ? (
                  <p>{t('messageSender.errorLoadingMessages', 'Error loading messages.')}</p>
                ) : messages.length === 0 ? (
                  <p>{t('messageSender.noMessages', 'No messages found.')}</p>
                ) : (
                  <ul className="message-list">
                    {sortedMessages.map((msg) => {
                      const isExpanded = expandedMessages.includes(msg.id);
                      const recipientsCount = msg.toPhoneNumbers ? Object.keys(msg.toPhoneNumbers).length : 0;
                      const recipientsArray = msg.toPhoneNumbers
                        ? Object.entries(msg.toPhoneNumbers).map(([phone, status]) => ({ phone, ...status }))
                        : [];

                      const showRecipients = showRecipientsForMessage === msg.id;

                      const isScheduledMessage = msg.messageType === 'scheduled' && msg.scheduledDateTime;
                      let canEdit = false;
                      let canDelete = false; 
                      const now = new Date();
                      if (isScheduledMessage && msg.status === 'pending') {
                        const diff = msg.scheduledDateTime - now;
                        if (diff > 5 * 60 * 1000) {
                          canEdit = true;
                          canDelete = true;
                        } 
                      }

                      const isEditingThisMessage = editingMessageId === msg.id;

                      return (
                        <li key={msg.id} className={`message-item ${isExpanded ? 'expanded' : ''}`}>
                          <div className="message-header">
                            <div className="message-collapsed-view">
                              {msg.imageUrl && (
                                <div className="message-image-container">
                                  <a href={msg.imageUrl} target="_blank" rel="noopener noreferrer">
                                    <img
                                      src={msg.imageUrl}
                                      alt="Message Attachment"
                                      className="message-image-thumb"
                                    />
                                  </a>
                                </div>
                              )}
                              <span className="message-date">
                                {msg.createdAt ? formatDate(msg.createdAt) : ''}
                              </span>
                              <span className={`message-type-badge ${msg.messageType}`}>
                                {msg.messageType}
                              </span>
                              <span className={`status-badge ${msg.status}`}>
                                {msg.status}
                              </span>
                              <span>{msg.id}</span>
                            </div>
                            <button
                              className={`toggle-details-button ${isExpanded ? 'expanded' : ''}`}
                              onClick={() => toggleMessageExpand(msg.id)}
                              aria-label={
                                isExpanded ? t('messageSender.collapse', 'Collapse') : t('messageSender.expand', 'Expand')
                              }
                            >
                              <FontAwesomeIcon icon={iconMap.faChevronDown} />
                            </button>
                          </div>
                          {isExpanded && (
                            <div className="message-details">
                              {isEditingThisMessage ? (
                                <>
                                  <TextField
                                    label={t('messageSender.optionalTitle', 'Title (optional)')}
                                    name="editTitle"
                                    value={editingTitle}
                                    onChange={setEditingTitle}
                                  />
                                  <MessageInput message={editingContent} setMessage={setEditingContent} />
                                  {isScheduledMessage && (
                                    <div className="input-group date-time-group">
                                      <DatePicker
                                        label={t('messageSender.selectDate', 'Select Date')}
                                        name="editingDate"
                                        value={editingDate}
                                        onChange={setEditingDate}
                                        placeholder={t('messageSender.datePlaceholder', 'MM/DD/YYYY')}
                                      />
                                      <TimePicker
                                        label={t('messageSender.selectTime', 'Select Time')}
                                        name="editingTime"
                                        value={editingTime}
                                        onChange={setEditingTime}
                                        placeholder={t('messageSender.timePlaceholder', 'HH:MM')}
                                      />
                                    </div>
                                  )}
                                  {error && <ErrorMessage error={error} />}
                                  <div className="action-buttons" style={{marginTop: '10px'}}>
                                    <ActionButton
                                      text={t('messageSender.saveChanges', 'Save Changes')}
                                      onClick={() => saveEditedMessage(msg)}
                                      icon="faCheck"
                                      colorType="primary"
                                      disabled={isLoading}
                                    />
                                    <ActionButton
                                      text={t('messageSender.cancel', 'Cancel')}
                                      onClick={cancelEditingMessage}
                                      icon="faTimes"
                                      colorType="secondary"
                                      disabled={isLoading}
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  {msg.title && (
                                    <p className="expanded-message-title">
                                      <strong>{msg.title}</strong>
                                    </p>
                                  )}
                                  <p className="expanded-message-content">
                                    <strong>{msg.content}</strong>
                                  </p>
                                  {msg.scheduledDateTime && (
                                    <p className="scheduled-info">
                                      {t('messageSender.scheduledFor', 'Scheduled for')}: {msg.scheduledDateTime.toLocaleString()}
                                    </p>
                                  )}
                                  <p className="recipients-line">
                                    {t('messageSender.recipients', 'Recipients')}: {recipientsCount}
                                    <span
                                      className={`recipients-chevron ${showRecipients ? 'open' : ''}`}
                                      onClick={() => toggleRecipientsList(msg.id)}
                                    >
                                      ▼
                                    </span>
                                  </p>
                                  {showRecipients && (
                                    <ul className="recipients-list">
                                      {recipientsArray.map((r, index) => (
                                        <li key={index} className="recipient-item">
                                          {r.phone} ({r.name})
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                  <div className="action-buttons" style={{marginTop: '10px'}}>
                                    {canEdit && (
                                      <ActionButton
                                        text={t('messageSender.edit', 'Edit')}
                                        onClick={() => startEditingMessage(msg)}
                                        icon="faEdit"
                                        colorType="secondary"
                                      />
                                    )}
                                    {canDelete && (
                                      <ActionButton
                                        text={t('messageSender.delete', 'Delete')}
                                        onClick={() => handleDeleteScheduledMessage(msg.id)}
                                        icon="faTrash"
                                        colorType="danger"
                                      />
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            ) : null}
          </div>
        ) : (
          /* If showHistory == false, we only show the "compose" form (if canCompose) */
          <form onSubmit={handleSubmit} className="login-form">
            {canCompose ? (
              <>
                {!single && (
                  <>
                    <SelectField
                      label={t('messageSender.recipientType', 'Send To')}
                      name="recipientType"
                      value={recipientType}
                      onChange={(value) => setRecipientType(value)}
                      options={recipientTypeOptions}
                      required
                    />
                    {recipientType === 'Groups' && (
                      <div className="input-group">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <label>{t('messageSender.selectGroups', 'Select Groups')}</label>
                          <ActionButton
                            text={t('messageSender.selectAll', 'Select All')}
                            onClick={handleSelectAllGroups}
                            icon="faCheck"
                            colorType="secondary"
                            size="small"
                          />
                        </div>
                        <Select
                          isMulti
                          options={groupsData.map((group) => ({
                            value: group.id,
                            label: group.name || group.groupName,
                            profilePicture: group.profilePicture || DefaultProfilePicture,
                          }))}
                          value={selectedGroups}
                          onChange={(selectedOptions) => setSelectedGroups(selectedOptions)}
                          styles={customStyles}
                          formatOptionLabel={formatOptionLabel}
                          classNamePrefix="react-select"
                        />
                      </div>
                    )}

                    {selectedTeamType && (
                      <div className="input-group">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <label>{t('messageSender.selectTeams', 'Select Teams')}</label>
                          <ActionButton
                            text={t('messageSender.selectAll', 'Select All')}
                            onClick={handleSelectAllTeams}
                            icon="faCheck"
                            colorType="secondary"
                            size="small"
                          />
                        </div>
                        <Select
                          isMulti
                          options={filteredTeams.map((team) => ({
                            value: team.id,
                            label: team.name,
                          }))}
                          value={selectedTeams}
                          onChange={(selectedOptions) => setSelectedTeams(selectedOptions || [])}
                          classNamePrefix="react-select"
                        />
                      </div>
                    )}

                    {recipientType === 'Members' && (
                      <div className="input-group">
                        <label>{t('messageSender.selectMembers', 'Select Members')}</label>
                        <Select
                          isMulti
                          options={members.map((member) => ({
                            value: member.id,
                            label: `${member.firstName} ${member.lastName}`,
                            profilePicture: member.profilePicture || DefaultProfilePicture,
                          }))}
                          value={selectedMembers}
                          onChange={(selectedOptions) => setSelectedMembers(selectedOptions)}
                          styles={customStyles}
                          formatOptionLabel={formatOptionLabel}
                          classNamePrefix="react-select"
                        />
                      </div>
                    )}
                  </>
                )}
                {isScheduled && (
                  <TextField
                    label={t('messageSender.optionalTitle', 'Title (optional)')}
                    name="title"
                    value={title}
                    onChange={setTitle}
                    placeholder={t('messageSender.titlePlaceholder', 'Enter a title for this message')}
                  />
                )}
                <div className="input-group">
                  <MessageInput message={message} setMessage={setMessage} />
                  <ImageUpload setImage={setImage} />
                  {imagePreviewUrl && <ImagePreview imagePreviewUrl={imagePreviewUrl} removeImage={removeImage} />}
                </div>
                {isScheduled && (
                  <div className="input-group date-time-group">
                    <DatePicker
                      label={t('messageSender.selectDate', 'Select Date')}
                      name="scheduledDate"
                      value={scheduledDate}
                      onChange={setScheduledDate}
                      placeholder={t('messageSender.datePlaceholder', 'MM/DD/YYYY')}
                    />
                    <TimePicker
                      label={t('messageSender.selectTime', 'Select Time')}
                      name="scheduledTime"
                      value={scheduledTime}
                      onChange={setScheduledTime}
                      placeholder={t('messageSender.timePlaceholder', 'HH:MM')}
                    />
                  </div>
                )}
                {error && <ErrorMessage error={error} />}
                <div className="action-buttons">
                  <ActionButton
                    type="submit"
                    text={mainButtonText}
                    icon="faPaperPlane"
                    disabled={isLoading}
                    size="large"
                    fullWidth={true}
                    colorType="primary"
                    style={{ display: canCompose ? 'inline-block' : 'none' }}
                    split={!onlyScheduled && canScheduleMessage}
                    onSplitClick={onlyScheduled ? undefined : handleScheduleToggle}
                    splitIcon="faClock"
                  />
                </div>
              </>
            ) : (
              <p style={{ color: 'red', marginTop: '1rem' }}>
                {t('messageSender.noPermissionToCompose', 'You do not have permission to send or schedule messages.')}
              </p>
            )}
          </form>
        )}
      </div>

      {showProgressModal && (
        <CustomModal
          show={showProgressModal}
          onClose={() => {}}
          title={t('messageSender.sendingMessages', 'Sending Messages')}
          closable={false}
        >
          <div className="progress-modal-content">
            <p>
              {t('messageSender.sendingTo', 'Sending to')}: {currentRecipient}
            </p>
            <progress value={progress} max={totalMessages}></progress>
            <p>
              {progress} / {totalMessages} {t('messageSender.messagesSent', 'messages sent')}
            </p>
          </div>
        </CustomModal>
      )}

      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </div>
  );
};

MessageSender.propTypes = {
  onClose: PropTypes.func,
  single: PropTypes.bool,
  recipient: PropTypes.string,
  showHistory: PropTypes.bool,
  onlyScheduled: PropTypes.bool,
};

MessageSender.defaultProps = {
  onClose: null,
  single: false,
  recipient: '',
  showHistory: false,
  onlyScheduled: false,
};

export default MessageSender;
