// src/components/LandingPage/LandingPage.jsx

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';

// Desktop logo
import CatholicoreLogo from '../../assets/CatholicoreLogo.png';
// Mobile logo
import CatholicoreMobileLogo from '../../assets/CatholicoreLogoMobile.png';
// Hero image
import ChurchImage from '../../assets/Church.png';
// About cross image
import PeopleCrossImage from '../../assets/PeopleCross.png';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconMap from '../Common/IconMap';

// Import the CustomModal
import CustomModal from '../Common/CustomModal';
// Import the CustomTooltip
import CustomTooltip from '../Common/CustomTooltip';

// Import Firebase and App Check modules
import firebase from 'firebase/compat/app';
import 'firebase/compat/app-check';

const LandingPage = () => {
  // Contact form state
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  // Modal visibility state
  const [showModal, setShowModal] = useState(false);

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submit (fire-and-forget fetch)
  const handleSubmit = async (e) => {
    e.preventDefault();

    // 1. Show modal immediately
    setShowModal(true);

    // Prepare the payload for the email submission
    const payload = {
      toAddress: 'info@catholicore.com',
      subject: `Contact form submission from ${formData.name}`,
      content: `
        <p><strong>Name:</strong> ${formData.name}</p>
        <p><strong>Email:</strong> ${formData.email}</p>
        <p><strong>Message:</strong></p>
        <p>${formData.message}</p>
      `,
    };

    try {
      // Retrieve the App Check token (force refresh by passing "true")
      const tokenResult = await firebase.appCheck().getToken(true);
      const appCheckToken = tokenResult.token;

      // 2. Fire the POST request with the App Check token in the header (fire-and-forget)
      fetch('https://sendemail-lgfph5hmwq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Firebase-AppCheck': appCheckToken,
        },
        body: JSON.stringify(payload),
      }).catch((error) => {
        console.error('Error sending email:', error);
      });
    } catch (error) {
      console.error('Error retrieving App Check token:', error);
    }

    // 3. Reset form
    setFormData({ name: '', email: '', message: '' });
  };

  // Fade-in on scroll with Intersection Observer
  useEffect(() => {
    const sections = document.querySelectorAll('section');
    const options = { threshold: 0.1 };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('lp-in-view');
          observer.unobserve(entry.target);
        }
      });
    }, options);

    sections.forEach((sec) => observer.observe(sec));
    return () => observer.disconnect();
  }, []);

  return (
    <div className="lp-container">
      {/* Header */}
      <header className="lp-header">
        {/* Desktop Logo */}
        <div className="lp-logo-desktop">
          <img src={CatholicoreLogo} alt="Catholicore Logo" />
        </div>
        {/* Mobile Logo */}
        <div className="lp-logo-mobile">
          <img src={CatholicoreMobileLogo} alt="Catholicore Mobile Logo" />
        </div>

        {/* Navigation Links */}
        <nav className="lp-nav-links">
          {/* Desktop links */}
          <a href="#about" className="lp-nav-link lp-hide-on-mobile">
            About
          </a>
          <a href="#features" className="lp-nav-link lp-hide-on-mobile">
            Features
          </a>
          <a href="#pricing" className="lp-nav-link lp-hide-on-mobile">
            Pricing
          </a>
          <a href="#contact" className="lp-nav-link lp-hide-on-mobile">
            Contact
          </a>
          {/* Sign In and Create Account */}
          <Link to="/login" className="lp-nav-button">
            Sign In
          </Link>
          <Link to="/sign-up" className="lp-nav-button lp-create-account-button">
            Create Account
          </Link>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="lp-hero" id="home">
        <div className="lp-wave-divider lp-wave-top"></div>
        <div className="lp-hero-content lp-hero-two-column">
          <div className="lp-hero-left">
            <h1 className="lp-hero-title">Catholicore</h1>
            <h2 className="lp-tagline">Building Stronger Parish Connections</h2>
            <p className="lp-hero-description">
              An all-in-one platform to manage membership, communication,
              events for modern parishes and religious groups.
            </p>
            <Link to="/sign-up" className="lp-cta-button lp-highlight-border">
              Get Started
            </Link>
          </div>
          <div className="lp-hero-right">
            <img
              src={ChurchImage}
              alt="Community in front of a church"
              className="lp-hero-image"
            />
          </div>
        </div>
        <div className="lp-wave-divider lp-wave-bottom"></div>
      </section>

      {/* About Section */}
      <section className="lp-about" id="about">
        <div className="lp-wave-divider lp-wave-top"></div>
        <div className="lp-about-two-column">
          <div className="lp-about-left">
            <h2>About Catholicore</h2>
            <div className="lp-about-text">
              <p>
                Catholicore is a powerful, intuitive platform created for parishes
                and religious communities seeking to streamline every aspect of
                their ministry. By blending technology with faith-based values,
                Catholicore empowers you to reduce administrative burdens,
                strengthen engagement, and focus on your core mission of spiritual growth.
              </p>
              <p>
                Founded to address the demands of parish management in the digital age,
                Catholicore delivers a seamless, user-friendly experience for both leaders
                and members alike. From membership to communications and events,
                our comprehensive tools give you the flexibility and support you need
                to build a thriving community.
              </p>
            </div>

            <div className="lp-info-grid">
              <div className="lp-info-box lp-info-box-hover">
                <h3>Our Vision</h3>
                <p>
                  “To revolutionize how religious organizations engage and flourish by
                  equipping them with innovative, seamless technology.”
                </p>
              </div>
              <div className="lp-info-box lp-info-box-hover">
                <h3>Our Mission</h3>
                <p>
                  “To empower faith-based groups with tools that simplify operations,
                  enhance communication, and foster deeper community connections.”
                </p>
              </div>
              <div className="lp-info-box lp-info-box-hover">
                <h3>Core Values</h3>
                <ul className="lp-core-values">
                  <li>Faith-Driven Purpose</li>
                  <li>Community-Centricity</li>
                  <li>Innovation in Service</li>
                  <li>Integrity in Practice</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="lp-about-right">
            <img
              src={PeopleCrossImage}
              alt="Crowd forming a cross"
              className="lp-about-image"
            />
          </div>
        </div>
        <div className="lp-wave-divider lp-wave-bottom"></div>
      </section>

      {/* Features Section */}
      <section className="lp-features" id="features">
        <div className="lp-wave-divider lp-wave-top"></div>
        <h2 className="lp-section-heading">Features</h2>
        <div className="lp-features-grid">
          <div className="lp-feature-card lp-feature-card-hover lp-highlight-outline">
            <FontAwesomeIcon icon={IconMap.faUsers} className="lp-feature-icon" />
            <h3>Membership Management</h3>
            <p>
              Effortlessly manage members with streamlined sign-ups, detailed profiles,
              and easy-to-use tracking tools.
            </p>
          </div>
          <div className="lp-feature-card lp-feature-card-hover lp-highlight-outline">
            <FontAwesomeIcon icon={IconMap.faEnvelope} className="lp-feature-icon" />
            <h3>Enhanced Communication</h3>
            <p>
              Deliver critical updates, spiritual messages, and engaging announcements
              through SMS, email, and push notifications.
            </p>
          </div>
          <div className="lp-feature-card lp-feature-card-hover lp-highlight-outline">
            <FontAwesomeIcon icon={IconMap.faCalendarAlt} className="lp-feature-icon" />
            <h3>Event Management</h3>
            <p>
              Organize parish events using an integrated calendar that keeps
              members informed, engaged, and eager to participate.
            </p>
          </div>
          <div className="lp-feature-card lp-feature-card-hover lp-highlight-outline">
            <FontAwesomeIcon icon={IconMap.faPaintBrush} className="lp-feature-icon" />
            <h3>Customization</h3>
            <p>
              Reflect your community’s identity with tailored color themes, layouts,
              and branding options.
            </p>
          </div>
          <div className="lp-feature-card lp-feature-card-hover lp-highlight-outline">
            <FontAwesomeIcon icon={IconMap.faUserShield} className="lp-feature-icon" />
            <h3>Team Empowerment</h3>
            <p>
              Assign roles and collaborate seamlessly across staff or volunteer teams
              with robust access controls.
            </p>
          </div>
          <div className="lp-feature-card lp-feature-card-hover lp-highlight-outline">
            <FontAwesomeIcon icon={IconMap.faFilePen} className="lp-feature-icon" />
            <h3>Custom Forms</h3>
            <p>
              Design custom forms for registrations, surveys, and more, tailored to your needs.
            </p>
          </div>
        </div>
        <div className="lp-wave-divider lp-wave-bottom"></div>
      </section>

      {/* Pricing Section */}
      <section className="lp-pricing" id="pricing">
        <div className="lp-wave-divider lp-wave-top"></div>
        <h2 className="lp-section-heading">Pricing</h2>
        <p className="lp-pricing-intro">
          Experience all the essential features and support you need. 
          Choose the plan that best aligns with your parish size and outreach goals.
        </p>

        <div className="lp-pricing-plans">
          {/* FREE PLAN */}
          <div className="lp-plan-card lp-plan-card-hover lp-highlight-outline">
            <h3>Free</h3>
            <h4>$0/month</h4>
            <ul>
              <li>Up to 15 Members</li>
              <li>Basic Event Management</li>
              <li>SMS/MMS: Pay-as-you-go</li>
            </ul>
            <Link to="/sign-up" className="lp-plan-button">
              Sign Up
            </Link>
          </div>

          {/* BASIC PLAN */}
          <div className="lp-plan-card lp-plan-card-hover lp-highlight-outline">
            <h3>Basic</h3>
            <h4>$29/month</h4>
            <ul>
              <li>Manage up to 100 Members</li>
              <li>
                500 monthly message credits{' '}
                <CustomTooltip text="Overage is $0.01 per SMS and $0.03 per MMS after 500 total credits.">
                  <FontAwesomeIcon icon={IconMap.faInfoCircle} className="lp-info-icon" />
                </CustomTooltip>
                <br />
                <em>(equivalent to 500 SMS or 100 MMS)</em>
              </li>
              <li>Essential Event Management</li>
              <li>Create up to 3 Groups</li>
            </ul>
            <Link to="/sign-up" className="lp-plan-button">
              Get Started
            </Link>
          </div>

          {/* STANDARD PLAN (MOST POPULAR) */}
          <div className="lp-plan-card lp-plan-card-hover lp-highlight-outline lp-plan-card-featured lp-most-popular-badge">
            <div className="lp-badge-label">Most Popular</div>
            <h3>Standard</h3>
            <h4>$79/month</h4>
            <ul>
              <li>Manage up to 300 Members</li>
              <li>
                1,500 monthly message credits{' '}
                <CustomTooltip text="Overage is $0.01 per SMS and $0.03 per MMS after 1,500 total credits.">
                  <FontAwesomeIcon icon={IconMap.faInfoCircle} className="lp-info-icon" />
                </CustomTooltip>
                <br />
                <em>(equivalent to 1,500 SMS or 300 MMS)</em>
              </li>
              <li>Full Event Management</li>
              <li>Scheduled SMS & Email Notifications</li>
              <li>Reports & Customer Support</li>
            </ul>
            <Link to="/sign-up" className="lp-plan-button">
              Choose Standard
            </Link>
          </div>

          {/* PREMIUM PLAN */}
          <div className="lp-plan-card lp-plan-card-hover lp-highlight-outline">
            <h3>Premium</h3>
            <h4>$149/month</h4>
            <ul>
              <li>Manage up to 1,000 Members</li>
              <li>
                5,000 monthly message credits{' '}
                <CustomTooltip text="Overage is $0.01 per SMS and $0.03 per MMS after 5,000 total credits.">
                  <FontAwesomeIcon icon={IconMap.faInfoCircle} className="lp-info-icon" />
                </CustomTooltip>
                <br />
                <em>(equivalent to 5,000 SMS or 1,000 MMS)</em>
              </li>
              <li>Advanced Analytics & Custom Reporting</li>
              <li>Priority Support</li>
              <li>All Standard Features</li>
            </ul>
            <Link to="/sign-up" className="lp-plan-button">
              Go Premium
            </Link>
          </div>

          {/* ENTERPRISE PLAN */}
          <div className="lp-plan-card lp-plan-card-hover lp-highlight-outline">
            <h3>Enterprise</h3>
            <h4>Contact Us</h4>
            <ul>
              <li>Unlimited Members</li>
              <li>All Premium Features</li>
              <li>Custom SMS/MMS Allowances</li>
              <li>Custom Integrations & Solutions</li>
              <li>Dedicated Priority Support</li>
            </ul>
            <a href="#contact" className="lp-plan-button">
              Contact Us
            </a>
          </div>
        </div>

        <div className="lp-wave-divider lp-wave-bottom"></div>
      </section>

      {/* Contact Section */}
      <section className="lp-contact" id="contact">
        <div className="lp-wave-divider lp-wave-top"></div>
        <h2 className="lp-section-heading">Contact Us</h2>
        <p className="lp-contact-intro">
          Have questions, need support, or want to learn more? Send us a message,
          and we’ll get back to you shortly.
        </p>
        <form className="lp-contact-form" onSubmit={handleSubmit}>
          <div className="lp-form-field">
            <label htmlFor="name">Name *</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="John Doe"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="lp-form-field">
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="john@example.com"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="lp-form-field">
            <label htmlFor="message">Message *</label>
            <textarea
              id="message"
              name="message"
              placeholder="How can we help you today?"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="lp-contact-button">Send Message</button>
        </form>
        <div className="lp-wave-divider lp-wave-bottom"></div>
      </section>

      {/* Footer */}
      <footer className="lp-footer">
        <p>&copy; {new Date().getFullYear()} Catholicore. All rights reserved.</p>
        <div className="lp-footer-links">
          <Link to="/policies?policy=privacy">Privacy Policy</Link>
          <Link to="/policies?policy=terms">Terms of Service</Link>
        </div>
      </footer>

      {/* Custom Modal (marketing-friendly message) */}
      <CustomModal
        show={showModal}
        onClose={() => setShowModal(false)}
        title=""
      >
        <div className="lp-marketing-modal">
          <h2>Thank You, {formData.name || 'Friend'}!</h2>
          <p>
            We’re thrilled you reached out to learn more about <strong>Catholicore</strong>.
            Our team is excited to show you how we can help your parish build stronger
            member connections and foster a thriving faith community.
          </p>
          <p>
            Keep an eye on your inbox — we’ll be in touch soon to guide you through
            the next steps.
          </p>
          <p>
            Meanwhile, feel free to explore our <Link to="/features">Features</Link> and{' '}
            <Link to="/pricing">Pricing</Link> to see how <strong>Catholicore</strong>
            can empower your ministry.
          </p>
          <div className="lp-modal-btn-wrapper">
            <button onClick={() => setShowModal(false)}>OK, Sounds Good!</button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default LandingPage;
