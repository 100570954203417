// src/components/Authentication/SignUpSteps/Step3Form.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconMap from '../../Common/IconMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Step3Form.css';

const Step3Form = ({ onNext, onBack, formData }) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState(formData.firstName || '');
  const [lastName, setLastName] = useState(formData.lastName || '');
  const [password, setPassword] = useState(formData.password || '');
  const [confirmPassword, setConfirmPassword] = useState(formData.confirmPassword || '');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setPasswordMatchError(t('signUp.passwordsDoNotMatch'));
      return;
    }
    setPasswordMatchError(''); // Clear any previous error
    onNext({ firstName, lastName, password });
  };

  const evaluatePasswordStrength = (password) => {
    let strength = '';
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})'
    );
    const mediumRegex = new RegExp(
      '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
    );
    if (strongRegex.test(password)) {
      strength = 'strong';
    } else if (mediumRegex.test(password)) {
      strength = 'medium';
    } else if (password.length > 0) {
      strength = 'weak';
    } else {
      strength = '';
    }
    setPasswordStrength(strength);
  };

  const handlePasswordChange = (e) => {
    const pwd = e.target.value;
    setPassword(pwd);
    evaluatePasswordStrength(pwd);
  };

  return (
    <div className="step3-container">
      <h2>{t('signUp.step3Title')}</h2>
      <form onSubmit={handleSubmit} className="sign-up-form">
        {/* First Name */}
        <div className="sign-up-input-group">
          <label htmlFor="firstName">{t('signUp.firstNameLabel')}</label>
          <input
            id="firstName"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder={t('signUp.firstNamePlaceholder')}
            required
            aria-label={t('signUp.firstNameLabel')}
          />
        </div>

        {/* Last Name */}
        <div className="sign-up-input-group">
          <label htmlFor="lastName">{t('signUp.lastNameLabel')}</label>
          <input
            id="lastName"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder={t('signUp.lastNamePlaceholder')}
            required
            aria-label={t('signUp.lastNameLabel')}
          />
        </div>

        {/* Password */}
        <div className="sign-up-input-group">
          <label htmlFor="password">{t('signUp.passwordLabel')}</label>
          <div className="password-field">
            <input
              id="password"
              type={passwordVisible ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              placeholder={t('signUp.passwordPlaceholder')}
              required
              minLength={6}
              aria-label={t('signUp.passwordLabel')}
            />
            <span
              className="password-toggle-icon"
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              <FontAwesomeIcon icon={passwordVisible ? IconMap.faEyeSlash : IconMap.faEye} />
            </span>
          </div>
          {passwordStrength && (
            <>
              <div className="password-strength-bars">
                <div className={`bar ${passwordStrength}`}></div>
                <div className={`bar ${passwordStrength !== 'weak' ? passwordStrength : ''}`}></div>
                <div className={`bar ${passwordStrength === 'strong' ? 'strong' : ''}`}></div>
              </div>
              <div className={`password-strength-text ${passwordStrength}`}>
                {t(`signUp.passwordStrength.${passwordStrength}`)}
              </div>
            </>
          )}
        </div>

        {/* Confirm Password */}
        <div className="sign-up-input-group">
          <label htmlFor="confirmPassword">{t('signUp.confirmPasswordLabel')}</label>
          <div className="password-field">
            <input
              id="confirmPassword"
              type={confirmPasswordVisible ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={t('signUp.confirmPasswordPlaceholder')}
              required
              minLength={6}
              aria-label={t('signUp.confirmPasswordLabel')}
            />
            <span
              className="password-toggle-icon"
              onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
            >
              <FontAwesomeIcon icon={confirmPasswordVisible ? IconMap.faEyeSlash : IconMap.faEye} />
            </span>
          </div>
          {passwordMatchError && (
            <div className="error-message">
              <FontAwesomeIcon icon={IconMap.faTimesCircle} className="error-icon" />
              <span>{passwordMatchError}</span>
            </div>
          )}
        </div>

        {/* Submit and Back Buttons */}
        <button type="submit" className="sign-up-submit-button">
          {t('signUp.nextButton')}
        </button>
      </form>
    </div>
  );
};

export default Step3Form;
