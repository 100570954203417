// src/components/Policies/PoliciesContainer.jsx

import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TermsService from './TermsService';
import PrivacyPolicy from './PrivacyPolicy';
import CatholicoreLogo from '../../assets/CatholicoreLogo.png'; // Import the logo
import './PoliciesContainer.css';

const PoliciesContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Memoize the creation of queryParams so that its reference only changes when location.search does.
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const initialPolicy = queryParams.get('policy');

  const getInitialPolicy = () => {
    if (initialPolicy === 'privacy') {
      return 'privacy';
    }
    return 'terms';
  };

  const [activePolicy, setActivePolicy] = useState(getInitialPolicy);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    setFadeIn(true);
  }, []);

  useEffect(() => {
    const currentPolicy = queryParams.get('policy');
    if (currentPolicy === 'privacy') {
      setActivePolicy('privacy');
    } else {
      setActivePolicy('terms');
    }
  }, [queryParams]);

  return (
    <div className={`policies-container ${fadeIn ? 'fade-in' : ''}`}>
      <div className="policies-left-panel">
        {/* Catholicore Logo at the top of the sidebar */}
        <img
          src={CatholicoreLogo}
          alt="Catholicore Logo"
          className="sidebar-logo"
        />
        <h2>Policies</h2>
        <button
          className={activePolicy === 'terms' ? 'active-tab' : ''}
          onClick={() => {
            setActivePolicy('terms');
            navigate('/policies?policy=terms');
          }}
        >
          Terms of Service
        </button>
        <button
          className={activePolicy === 'privacy' ? 'active-tab' : ''}
          onClick={() => {
            setActivePolicy('privacy');
            navigate('/policies?policy=privacy');
          }}
        >
          Privacy Policy
        </button>
        <button className="home-button" onClick={() => navigate('/')}>
          Go Back to Home
        </button>
      </div>

      <div className="policies-right-panel">
        {activePolicy === 'terms' && <TermsService />}
        {activePolicy === 'privacy' && <PrivacyPolicy />}
      </div>
    </div>
  );
};

export default PoliciesContainer;
