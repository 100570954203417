// src/contexts/ValidationContext.jsx

import React, { createContext } from 'react';
import { firestore } from '../firebase';
import {
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  serverTimestamp,
  Timestamp,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

// Import Firebase, App Check, and Auth modules
import firebase from 'firebase/compat/app';
import 'firebase/compat/app-check';
import 'firebase/compat/auth';

export const ValidationContext = createContext();

/* ============================================
   1. Original/Common Reserved Subdomains
   (Generic names commonly used in many applications)
   ============================================ */
const originalReserved = [
  "admin", "www", "mail", "ftp", "webmail", "support", "help", "api", "blog", "shop",
  "secure", "login", "logout", "account", "accounts", "user", "users", "billing", "developer", "developers",
  "demo", "test", "staging", "sandbox", "beta", "m", "mobile", "news", "feed", "rss",
  "assets", "img", "images", "video", "videos", "adminpanel", "dashboard", "portal", "helpdesk", "careers",
  "job", "jobs", "forum", "forums", "privacy", "terms", "legal", "contact", "info", "about",
  "press", "media", "events", "community", "newsletter", "store", "cart", "checkout", "order", "orders",
  "subscribe", "unsubscribe", "feedback", "report", "security", "moderation", "backup", "data", "download", "downloads",
  "files", "file", "server", "webmaster", "root", "site", "system", "manage", "manager", "management",
  "service", "services", "finance", "payment", "payments", "reports", "analytics", "stats", "statistics", "config",
  "configuration", "docs", "documentation", "v1", "v2", "demo2", "testenv", "adminarea", "reserved", "supportdesk",
];

/* ============================================
   2. Development/Testing and Environment Terms
   (Names typically used in non-production environments)
   ============================================ */
const devReserved = [
  "uat", "prod", "production", "dev", "development", "qa", "local", "preview", "cdn", "static", "cache", "ops", "experimental",
];

/* ============================================
   3. Internal/Production Endpoints and Infrastructure
   (Names that might conflict with internal tools, dashboards, or endpoints)
   ============================================ */
const internalReserved = [
  "app", "apps", "members", "team", "internal", "intranet", "corporate", "clients", "customers", "live",
  "status", "office", "hooks", "updates", "metrics", "tracking", "health",
];

/* ============================================
   4. Authentication, Profile & Communication Related
   (Names used for sign-in, user profiles, or messaging that should be reserved)
   ============================================ */
const authReserved = [
  "sso", "signup", "signin", "signout", "register", "auth", "authentication",
  "profile", "profiles", "review", "reviews", "preprod", "monitor", "monitoring", "email",
];

/* ============================================
   5. Partner, Marketing, and UI/UX Related Terms
   (Names that might be used for customer-facing or partner-related pages)
   ============================================ */
const partnerReserved = [
  "partner", "partners", "sales", "marketing", "advertising", "research", "insights", "explore", "connect", "network",
  "hosting", "ui", "ux", "design", "lab", "labs", "main", "core", "home", "default", "audio", "music",
];

/* ============================================
   6. Miscellaneous Common Terms
   (Other names that might cause confusion or conflict)
   ============================================ */
const miscReserved = [
  "faq", "console", "oauth", "marketplace", "control", "controlpanel", "sitemap", "logs"
];

// Combine all reserved arrays into one list.
const reservedSubdomains = [
  ...originalReserved,
  ...devReserved,
  ...internalReserved,
  ...authReserved,
  ...partnerReserved,
  ...miscReserved,
];

export const ValidationProvider = ({ children }) => {
  /**
   * Function to send validation code to the user's email
   */
  const sendValidationCode = async (email) => {
    const validationCode = uuidv4().replace(/-/g, '').substring(0, 6).toUpperCase();
    const expireAt = Timestamp.fromDate(new Date(Date.now() + 15 * 60 * 1000)); // Expires in 15 minutes

    // Save the validation code in Firestore
    const validationCodeDocRef = doc(firestore, 'validationCodes', email);
    await setDoc(validationCodeDocRef, {
      email,
      validationCode,
      createdAt: serverTimestamp(),
      expireAt,
    });

    // Prepare the email content using a professional HTML template
    const subject = 'Your Verification Code';
    const content = getVerificationEmailHtml(validationCode);

    // Retrieve the App Check token (force refresh)
    const tokenResult = await firebase.appCheck().getToken(true);
    const appCheckToken = tokenResult.token;

    // Send the validation code via email by calling the cloud function
    const sendEmailResponse = await fetch('https://sendemail-lgfph5hmwq-uc.a.run.app', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Firebase-AppCheck': appCheckToken,
      },
      body: JSON.stringify({
        toAddress: email,
        subject,
        content,
      }),
    });

    if (!sendEmailResponse.ok) {
      throw new Error('Failed to send validation email');
    }
  };

  // Function to generate the HTML content for the verification email
  const getVerificationEmailHtml = (validationCode) => {
    return `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="UTF-8">
        <title>Verify Your Email - Catholicore</title>
        <link href="https://fonts.googleapis.com/css?family=Montserrat:400,600" rel="stylesheet" type="text/css">
        <style type="text/css">
          body {
            margin: 0;
            padding: 0;
            background-color: #f4f4f4;
            font-family: 'Montserrat', sans-serif;
          }
          .container {
            width: 100%;
            background-color: #f4f4f4;
            padding: 20px 0;
          }
          .email-wrapper {
            width: 100%;
            max-width: 600px;
            background-color: #ffffff;
            margin: 0 auto;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0 4px 12px rgba(0,0,0,0.1);
          }
          .header {
            background-color: #bb8300;
            padding: 20px;
            text-align: center;
          }
          .header img {
            max-height: 60px;
          }
          .hero {
            padding: 40px 30px;
            text-align: center;
          }
          .hero h1 {
            font-size: 24px;
            color: #333333;
            margin-bottom: 20px;
          }
          .content {
            font-size: 16px;
            color: #555555;
            line-height: 1.6;
            margin-bottom: 30px;
          }
          .code {
            font-size: 36px;
            font-weight: 600;
            color: #bb8300;
            background-color: #fdf2e9;
            padding: 20px;
            border-radius: 4px;
            letter-spacing: 3px;
            margin-bottom: 30px;
            display: inline-block;
          }
          .cta {
            text-align: center;
            margin-bottom: 30px;
          }
          .cta a {
            background-color: #bb8300;
            color: #ffffff;
            padding: 15px 30px;
            text-decoration: none;
            border-radius: 4px;
            font-weight: 600;
          }
          .footer {
            background-color: #f4f4f4;
            text-align: center;
            padding: 20px;
            font-size: 12px;
            color: #999999;
          }
          @media only screen and (max-width: 600px) {
            .hero h1 {
              font-size: 20px;
            }
            .code {
              font-size: 28px;
              padding: 15px;
            }
            .cta a {
              padding: 12px 25px;
            }
          }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="email-wrapper">
            <div class="header">
              <img src="https://catholicore.com/static/media/CatholicoreLogoWhite.2b64249d4eacac07ba63.png" alt="Catholicore Logo">
            </div>
            <div class="hero">
              <h1>Welcome to Catholicore!</h1>
              <p class="content">We're excited to have you join our community. To get started, please verify your email address by entering the code below on our website.</p>
              <div class="code">${validationCode}</div>
              <p class="content">If you did not sign up for a Catholicore account, please disregard this email.</p>
            </div>
            <div class="footer">
              © ${new Date().getFullYear()} Catholicore. All rights reserved.
            </div>
          </div>
        </div>
      </body>
    </html>
    `;
  };

  /**
   * Function to verify the validation code
   */
  const verifyValidationCode = async (email, code) => {
    const validationCodeDocRef = doc(firestore, 'validationCodes', email);
    const validationCodeSnapshot = await getDoc(validationCodeDocRef);

    if (!validationCodeSnapshot.exists()) {
      throw new Error('Invalid code');
    }

    const data = validationCodeSnapshot.data();
    if (data.validationCode !== code) {
      throw new Error('Invalid code');
    }

    if (data.expireAt.toDate() < new Date()) {
      throw new Error('Code expired');
    }

    // Optionally, delete the validation code after successful verification
    await deleteDoc(validationCodeDocRef);
  };

  /**
   * Function to check if a user exists by directly querying the Firestore 'users' collection.
   * Returns an object containing the user's firstName and lastName if the user exists,
   * otherwise returns null.
   */
  const checkUserExists = async (email) => {
    try {
      const usersRef = collection(firestore, 'users');
      const qExisting = query(usersRef, where('email', '==', email));
      const querySnapshot = await getDocs(qExisting);
      if (querySnapshot.empty) {
        return null;
      } else {
        const userDoc = querySnapshot.docs[0].data();
        return { firstName: userDoc.firstName, lastName: userDoc.lastName };
      }
    } catch (error) {
      console.error('Error checking user existence in Firestore:', error);
      throw error;
    }
  };

  /**
   * Function to check if a subdomain is unique by querying Firestore's 'groups' collection.
   * In addition to checking Firestore, it verifies that the subdomain is not in the reserved list.
   * Returns true if the subdomain is unique, false if it is already taken or reserved.
   */
  const checkSubdomainUnique = async (subdomain) => {
    try {
      const lowerSub = subdomain.toLowerCase();
      // First, check if the subdomain is in the reserved list.
      if (reservedSubdomains.includes(lowerSub)) {
        return false;
      }
      // Then, query Firestore to see if any group already uses this subdomain.
      const groupsRef = collection(firestore, 'groups');
      const q = query(groupsRef, where('subdomain', '==', subdomain));
      const querySnapshot = await getDocs(q);
      return querySnapshot.empty;
    } catch (error) {
      console.error('Error checking subdomain uniqueness in Firestore:', error);
      throw error;
    }
  };

  return (
    <ValidationContext.Provider
      value={{
        sendValidationCode,
        verifyValidationCode,
        checkUserExists,
        checkSubdomainUnique,
      }}
    >
      {children}
    </ValidationContext.Provider>
  );
};

export default ValidationProvider;
