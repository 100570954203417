import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../Common/Spinner';
import ContextMenu from '../../Common/ContextMenu';
import placeholderImage from '../../../assets/placeholder.png';
import './ImagePicker.css';

// Example context for org data:
import { AppSettingsContext } from '../../../contexts/AppSettingsContext';

// Import firebase to use App Check
import firebase from 'firebase/compat/app';
import 'firebase/compat/app-check';

/**
 * Loads a file or blob into an <img> element.
 */
async function loadImageElement(fileOrBlob) {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(fileOrBlob);
    const img = new Image();
    img.onload = () => {
      URL.revokeObjectURL(url);
      resolve(img);
    };
    img.onerror = reject;
    img.src = url;
  });
}

/**
 * Main-thread image resizing to fit.
 *
 * This function:
 * 1. Loads the image.
 * 2. Computes a scaling factor so the entire image fits within a targetSize × targetSize square.
 * 3. Creates a canvas of size targetSize × targetSize, fills its background (optional),
 *    and draws the scaled image centered on the canvas.
 *
 * Returns a Blob in JPEG or PNG format.
 */
async function resizeImageToFit(file, targetSize = 200, quality = 0.8) {
  const img = await loadImageElement(file);
  // Compute scale to fit the image entirely inside the target dimensions.
  const scale = Math.min(targetSize / img.width, targetSize / img.height);
  const newWidth = img.width * scale;
  const newHeight = img.height * scale;
  
  // Create a canvas of fixed size.
  const canvas = document.createElement('canvas');
  canvas.width = targetSize;
  canvas.height = targetSize;
  const ctx = canvas.getContext('2d');
  
  // (Optional) Fill the canvas background. Change color if needed.
  ctx.fillStyle = '#fff';
  ctx.fillRect(0, 0, targetSize, targetSize);
  
  // Calculate offsets to center the image in the canvas.
  const offsetX = (targetSize - newWidth) / 2;
  const offsetY = (targetSize - newHeight) / 2;
  ctx.drawImage(img, offsetX, offsetY, newWidth, newHeight);
  
  return new Promise((resolve) => {
    const outputType = file.type.includes('png') ? 'image/png' : 'image/jpeg';
    canvas.toBlob((blob) => resolve(blob), outputType, quality);
  });
}

/**
 * Helper function for image resizing.
 * For this example, we resize the image so that it entirely fits into the preview container.
 */
async function resizeImage(file, targetSize = 200, quality = 0.8) {
  return await resizeImageToFit(file, targetSize, quality);
}

/**
 * POST the file to your upload endpoint.
 * Retrieves the App Check token from Firebase and includes it in the request header.
 */
async function uploadImageImmediately(file, folderPath) {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('folderPath', folderPath);

  // Retrieve the App Check token.
  const tokenResult = await firebase.appCheck().getToken(true);
  const appCheckToken = tokenResult.token;

  const res = await fetch('https://uploadimage-lgfph5hmwq-uc.a.run.app', {
    method: 'POST',
    headers: {
      'X-Firebase-AppCheck': appCheckToken,
    },
    body: formData,
  });
  if (!res.ok) {
    throw new Error(`uploadImageImmediately error: ${res.status}`);
  }
  const data = await res.json();
  return data.imageUrl;
}

const ImagePicker = ({
  label,
  name,
  value,
  onChange,
  required,
  imageShape = 'circular',
  maxFileSize = 5120, // ~5MB
  defaultImage,
  maxDimension = 200, // target preview width/height (in px)
  responseId = 'temp', // fallback if not passed
  shouldCompress, // if false, skip resizing
}) => {
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // For context menu.
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

  // Get organization data from context.
  const { organizationData } = useContext(AppSettingsContext);
  // Build folder path: "orgId/groupId/members"
  const folderPath = organizationData
    ? `${organizationData.organizationId}/${organizationData.groupId}/members`
    : 'fallbackFolder';

  // Clean up old blob URLs.
  const prevPreviewUrlRef = useRef(null);
  useEffect(() => {
    return () => {
      if (prevPreviewUrlRef.current && prevPreviewUrlRef.current.startsWith('blob:')) {
        URL.revokeObjectURL(prevPreviewUrlRef.current);
      }
    };
  }, []);

  // Set initial preview.
  useEffect(() => {
    if (typeof value === 'string' && value.trim() !== '') {
      setPreviewUrl(value);
      prevPreviewUrlRef.current = null;
    } else if (defaultImage) {
      setPreviewUrl(defaultImage);
      prevPreviewUrlRef.current = null;
    } else {
      setPreviewUrl(placeholderImage);
      prevPreviewUrlRef.current = null;
    }
  }, [value, defaultImage]);

  /**
   * When the user picks a file.
   */
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      onChange(null);
      setPreviewUrl(defaultImage || placeholderImage);
      setError('');
      return;
    }

    // Check file size.
    const fileSizeKB = file.size / 1024;
    if (fileSizeKB > maxFileSize) {
      alert(`File is ${Math.round(fileSizeKB)}KB, exceeds limit of ${maxFileSize}KB.`);
      return;
    }

    setLoading(true);
    setError('');

    try {
      let finalFile;

      if (shouldCompress) {
        // Resize the image so that it fits (with letterboxing) inside the preview container.
        const resizedBlob = await resizeImage(file, maxDimension, 0.8);

        // Build a new file name using a timestamp.
        const now = new Date();
        const mm = String(now.getMonth() + 1).padStart(2, '0');
        const dd = String(now.getDate()).padStart(2, '0');
        const yy = String(now.getFullYear()).slice(-2);
        const hh = String(now.getHours()).padStart(2, '0');
        const min = String(now.getMinutes()).padStart(2, '0');
        const sec = String(now.getSeconds()).padStart(2, '0');
        const timestamp = `${mm}${dd}${yy}_${hh}${min}${sec}`;
        const extension = file.type.includes('png') ? '.png' : '.jpg';
        const newFileName = `${responseId}_${timestamp}${extension}`;

        // Convert the Blob to a File.
        finalFile = new File([resizedBlob], newFileName, {
          type: file.type.includes('png') ? 'image/png' : 'image/jpeg',
          lastModified: Date.now(),
        });
      } else {
        // If resizing is disabled, use the original file.
        finalFile = file;
      }

      // Upload the file.
      const uploadedUrl = await uploadImageImmediately(finalFile, folderPath);

      // Update preview and notify parent.
      setPreviewUrl(uploadedUrl);
      onChange(uploadedUrl);
    } catch (err) {
      console.error('Error processing/uploading image:', err);
      setError('Failed to process image.');
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = () => {
    onChange(null);
    setPreviewUrl(defaultImage || placeholderImage);
    setError('');
  };

  // Context menu handlers.
  const handleOpenContextMenu = (e) => {
    e.preventDefault();
    const rect = e.currentTarget.getBoundingClientRect();
    setContextMenuPosition({ x: rect.left, y: rect.bottom });
    setIsContextMenuVisible(true);
  };
  const handleCloseContextMenu = () => {
    setIsContextMenuVisible(false);
  };

  return (
    <div className="image-picker-container">
      <div className="image-picker">
        <input
          type="file"
          id={name}
          name={name}
          accept="image/png, image/jpeg"
          onChange={handleImageChange}
          className="image-picker-input"
        />
        <label htmlFor={name} className="image-picker-label">
          <div className="image-picker-wrapper">
            <div
              className={`image-picker-icon ${imageShape}`}
              onContextMenu={handleOpenContextMenu}
            >
              {loading ? (
                <div className="image-spinner">
                  <Spinner size="50px" fullScreen={false} />
                </div>
              ) : (
                <img
                  src={previewUrl}
                  alt={label || 'Selected'}
                  onError={() => {
                    setPreviewUrl(defaultImage || placeholderImage);
                    setError('Failed to load image.');
                  }}
                />
              )}
            </div>
            {previewUrl &&
              previewUrl !== placeholderImage &&
              previewUrl !== defaultImage &&
              !loading && (
                <button
                  type="button"
                  className="three-dot-menu"
                  onClick={handleOpenContextMenu}
                  aria-label="Image options"
                >
                  ⋮
                </button>
              )}
          </div>
          {label && (
            <span className="image-picker-text">
              {label}
              {required && <span className="required-asterisk">*</span>}
            </span>
          )}
        </label>
      </div>
      {error && <p className="image-picker-error">{error}</p>}
      <ContextMenu
        isVisible={isContextMenuVisible}
        position={contextMenuPosition}
        onClose={handleCloseContextMenu}
        options={[{ label: 'Remove', onClick: handleRemove }]}
      />
    </div>
  );
};

ImagePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  imageShape: PropTypes.oneOf(['circular', 'square']),
  maxFileSize: PropTypes.number,
  defaultImage: PropTypes.string,
  maxDimension: PropTypes.number,
  responseId: PropTypes.string,
  shouldCompress: PropTypes.bool, // if false, skip resizing
};

ImagePicker.defaultProps = {
  label: '',
  value: null,
  required: false,
  imageShape: 'circular',
  maxFileSize: 5120,
  defaultImage: null,
  maxDimension: 200,
  responseId: 'temp',
  shouldCompress: true,
};

export default ImagePicker;
