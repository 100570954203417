// src/components/Authentication/Login.jsx

import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Contexts
import { AuthContext } from '../../contexts/AuthContext';
import { UserContext } from '../../contexts/UserContext';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';
import { useColorSettings } from '../Common/ColorSettingsContext';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';

import AuthenticationContainer from './AuthenticationContainer';
import './Login.css';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId = '' } = useParams();

  // Access the AuthContext for sign-in, currentUser, etc.
  const { signIn } = useContext(AuthContext);
  const { appSettings } = useContext(AppSettingsContext);

  // Access the UserContext for roles, user data, etc.
  const { userRoles, userRolesLoading, currentUser, currentUserLoading } = useContext(UserContext);

  // Access color/language loading states
  const { loading: colorLoading } = useColorSettings();
  const { loading: languageLoading } = useLanguageSettings();

  // Local component state
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(null);

  // Determine overall loading
  const isLoading =
    currentUserLoading || userRolesLoading || colorLoading || languageLoading;

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoginError(null); // Reset any old errors

    try {
      await signIn(email, password);
      // Once signIn completes, the AuthContext’s onAuthStateChanged
      // will update currentUser (and UserContext will follow).
    } catch (error) {
      if (error.code === 'auth/invalid-credential') {
        setLoginError('Your sign-in credentials are invalid or have expired. Please try again.');
      } else if (
        error.code === 'auth/user-not-found' ||
        error.code === 'auth/wrong-password'
      ) {
        setLoginError('Invalid email or password. Please try again.');
      } else {
        setLoginError(error.message || 'Sign-in failed.');
      }
    }
  };

  /**
   * Post-login redirection:
   * Once the user is logged in and roles are loaded,
   * decide where to navigate.
   */
  useEffect(() => {
    if (isLoading) return; // Wait until everything is loaded
    if (!currentUser) return; // If no currentUser, stay on login

    if (userRoles == null) return; // Possibly still loading

    // If user *is* logged in:
    if (groupId) {
      navigate(`/${groupId}/main`, { replace: true });
    } else if (userRoles.length === 1) {
      // Single role => jump directly to group
      const [firstRoleEntry] = userRoles;
      const userGroupId = firstRoleEntry.groupId.id;
      navigate(`/${userGroupId}/main`, { replace: true });
    } else if (userRoles.length > 1) {
      // Multiple => user picks from an organization list
      navigate('/organization-selection', { replace: true });
    } else {
      // No roles => fallback
      navigate('/organization-selection', { replace: true });
    }
  }, [currentUser, userRoles, groupId, isLoading, navigate]);

  // Left panel content for your authentication container
  const leftPanelContent = (
    <>
      <h2 className="sign-in-title">
        {t('login.signInTitle') || 'Sign In to Catholicore'}
      </h2>

      <form onSubmit={handleSubmit} className="login-form">
        <div className="login-input-group">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t('login.emailPlaceholder') || 'Your Email Address'}
            required
            aria-label="Email"
          />
        </div>

        <div className="login-input-group">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t('login.passwordPlaceholder') || 'Your Password'}
            required
            minLength={6}
            aria-label="Password"
          />
        </div>

        <button type="submit" className="login-submit-button">
          {t('login.submitButton') || 'Sign In'}
        </button>

        {/* Show login errors if any */}
        {loginError && (
          <div className="login-error">
            {loginError}
          </div>
        )}
      </form>

      <div className="login-links-container">
        <button
          type="button"
          onClick={() =>
            groupId
              ? navigate(`/${groupId}/password-recovery`)
              : navigate('/password-recovery')
          }
          className="link-button"
        >
          {t('login.needHelp') || 'Need help signing in?'}
        </button>
        <button
          type="button"
          onClick={() => navigate('/sign-up')}
          className="link-button"
        >
          {t('login.createAccount') || 'Create Your Free Account'}
        </button>
      </div>
    </>
  );

  return (
    <AuthenticationContainer
      isLoading={isLoading}
      error={!!loginError}
      errorMessage={loginError}
      appSettings={appSettings}
      leftPanelComponent={leftPanelContent}
    />
  );
};

export default Login;
