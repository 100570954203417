// src/components/Policies/PrivacyPolicy.jsx

import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-background">
      <div className="privacy-policy-container">
        <header className="privacy-policy-header">
          <h1>Privacy Policy</h1>
          <h2>Catholicore</h2>
        </header>

        <div className="privacy-policy-content">
          <div className="policy-dates">
            <p><strong>Effective Date:</strong> December 26th, 2024</p>
            <p><strong>Last Updated:</strong> February 6th, 2025</p>
          </div>

          <article>
            <h3>Introduction</h3>
            <p>
              Welcome to Catholicore, a dedicated platform for Catholic parishes and religious groups
              to manage their members efficiently. We are committed to protecting your privacy and ensuring
              a positive experience on our platform. This Privacy Policy outlines how we collect, use,
              disclose, and safeguard your information when you use our services.
            </p>
          </article>

          <article>
            <h3>Information We Collect</h3>
            <ul>
              <li>
                <strong>Personal Information:</strong> When you register with Catholicore, we collect information
                that personally identifies you, such as your name, email address, phone number, and any other
                information you provide.
              </li>
              <li>
                <strong>Group Communications:</strong> We collect information related to your participation in
                group communications, including messages, event participation, and other interactions.
              </li>
              <li>
                <strong>Usage and Device Information:</strong> We automatically collect data about your access,
                browser type, device information, and usage patterns.
              </li>
              <li>
                <strong>Billing Information:</strong> To process payments, we collect billing details like credit
                card information and billing addresses.
              </li>
            </ul>
          </article>

          <article>
            <h3>How We Use Your Information</h3>
            <ul>
              <li>
                <strong>To Provide Services:</strong> We use your data to manage your account, facilitate communications,
                display events, and customize your experience.
              </li>
              <li>
                <strong>To Improve Our Services:</strong> We analyze usage data to enhance functionality and quality.
              </li>
              <li>
                <strong>To Communicate:</strong> We may send you updates, promotional materials, and notifications.
              </li>
              <li>
                <strong>Communications Preferences:</strong> If you opt in, we will use your contact details for service
                updates and promotions. You can update or withdraw this consent at any time.
              </li>
              <li>
                <strong>Billing and Payments:</strong> Your billing information is used solely to process transactions.
              </li>
            </ul>
          </article>

          <article>
            <h3>Sharing of Your Information</h3>
            <p>
              We do not sell or rent your personal information. We may share data only with trusted service providers,
              in legal situations, or as part of a business transfer.
            </p>
          </article>

          <article>
            <h3>Data Security</h3>
            <p>
              We implement technical and organizational measures to protect your data, though no method is 100% secure.
            </p>
          </article>

          <article>
            <h3>Your Rights</h3>
            <p>
              You may access, correct, or delete your personal data. To exercise these rights, please contact us at
              <a href="mailto:info@catholicore.com"> info@catholicore.com</a>.
            </p>
          </article>

          <article>
            <h3>Children's Privacy</h3>
            <p>
              Catholicore is not intended for users under 13. We do not knowingly collect data from children.
            </p>
          </article>

          <article>
            <h3>Changes to This Privacy Policy</h3>
            <p>
              We may update this policy from time to time. The latest version will always be available on our website.
            </p>
          </article>

          <article>
            <h3>Contact Us</h3>
            <p>
              For questions or concerns, please email us at <a href="mailto:info@catholicore.com">info@catholicore.com</a>.
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
